import { useEffect, useState } from "react";

import { access } from "fs";
import { Link } from "raviger";

import { alertInfo } from "../../../actions/AlertActions";
import { useAppActions } from "../../../actions/AppActions";
import { getMyVendor } from "../../../api/Api";
import { NoManagerIcon } from "../../../components/clients/ClientAppIcons";
import { AddIconBlue, SearchIcon } from "../../../components/common/AppIcons";
import NoDataComponent from "../../../components/common/NoDataComponent";
import { SuspenseBoundary } from "../../../components/common/core/SuspenseBoundary";
import { deepFilter } from "../../../utils/StateUtils";
import { ClientManagerItem } from "./ClientManagerItem";

export default function ClientManagers({
  clientId,
  clientName,
  clientClass,
  managers,
  refreshCB,
}) {
  const [searchText, setSearchText] = useState("");
  const [vendorData, setVendorData] = useState({});
  const { createManager } = useAppActions();

  const handleAddManager = (label = "Manager") => {
    vendorData.type === "PMC" &&
      alertInfo(
        "Note: You are adding a non-user Manager. To create a user Manager, create from Settings -> Users -> New User"
      );
    createManager({
      label,
      clientId,
      callback: refreshCB,
    });
  };

  const searchManagers = deepFilter(searchText);

  const filteredManagers = managers?.filter(searchManagers);

  const isHOA = clientClass === "HOA";

  const contactLabel = isHOA ? "Board Member" : "Manager";

  useEffect(() => {
    getMyVendor().then((data) => {
      setVendorData(data);
    });
  }, []);

  return (
    <div className="flex flex-col">
      <div className="flex flex-col md:flex-row justify-between md:items-end my-2">
        <div className="flex flex-row gap-4 items-center my-2">
          <div className="flex gap-2 font-semibold">
            {clientName && (
              <Link href={`/commercial/${clientId}/summary`}>{clientName}</Link>
            )}
            {contactLabel}s
          </div>
          <div>
            <button
              className="border rounded text-sm px-2 py-1 bg-newBlue-500 text-newBlue-400 my-2"
              onClick={() => handleAddManager(contactLabel)}
            >
              <div className="flex flex-row items-center">
                <AddIconBlue className="h-4 w-4" />
                <span className="px-2">Add {contactLabel}</span>
              </div>
            </button>
          </div>
        </div>
        {filteredManagers?.length > 0 && (
          <div className="flex flex-col md:flex-row gap-2 items-center">
            <div className="flex flex-row items-center px-2 rounded bg-newGray-1400 ">
              <SearchIcon />
              <input
                id="client_managers_search"
                className="focus:outline-none p-2 text-gray-700 bg-newGray-1400 w-52"
                placeholder="Search..."
                value={searchText}
                onChange={(e) => {
                  setSearchText(e.target.value);
                }}
              />
            </div>
          </div>
        )}
      </div>
      <SuspenseBoundary waitFor={filteredManagers && filteredManagers?.length}>
        {filteredManagers?.length === 0 ? (
          <NoDataComponent
            icon={<NoManagerIcon />}
            lines={[
              `Oops! Couldn’t find any ${contactLabel}s!`,
              `Add a new ${contactLabel.toLowerCase()} by clicking the button below`,
            ]}
            buttonText={"Add " + contactLabel}
            buttonDisplay={true}
            buttonAction={() => handleAddManager(contactLabel)}
          />
        ) : (
          <ManagersList
            accessControlled={!isHOA}
            managers={filteredManagers}
            refreshCB={refreshCB}
            isHOA={isHOA}
          />
        )}
      </SuspenseBoundary>
    </div>
  );
}

const ManagersList = ({ accessControlled, managers, refreshCB, isHOA }) => {
  const { refreshManagerAccessData } = useAppActions();

  // TODO: This is a hack to update the manager object in the global state
  // when the manager object is updated in the managers array.
  useEffect(() => {
    refreshManagerAccessData({ managers });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [managers]);

  return (
    <>
      {managers
        ?.filter((manager, index, self) => {
          return (
            index === self.findIndex((m) => m.contactId === manager.contactId)
          );
        })
        .map((manager, index) => (
          <div key={index} className="py-2">
            <div className="py-2 border rounded-lg border-newGray-1500 flex flex-col ">
              <ClientManagerItem
                accessControlled={accessControlled}
                manager={manager}
                refreshCB={refreshCB}
                isHOA={isHOA}
              />
            </div>
          </div>
        ))}
    </>
  );
};
