import { useEffect, useState } from "react";

import { alertSuccess } from "../../../../../actions/AlertActions";
import { getProspectById, updateProspect } from "../../../../../api/Api";
import { AddIconBlue } from "../../../../../components/common/AppIcons";
import MaterialForm from "../../../../../components/common/form/MaterialForm";

type IncomeDetailsTabProps = {
  prospectId: number;
  setCurrentApplicationTab: React.Dispatch<React.SetStateAction<string>>;
  setDetailsTab: React.Dispatch<React.SetStateAction<string>>;
};

export function IncomeDetailsTab({
  prospectId,
  setCurrentApplicationTab,
  setDetailsTab,
}: IncomeDetailsTabProps) {
  const [employmentDetails, setEmploymentDetails] = useState([
    {
      employer: "",
      position: "",
      fromDate: "",
      toDate: "",
      monthlyIncome: 0,
      employerAddress: "",
      supervisorName: "",
      supervisorPhone: "",
    },
  ]);
  const [incomeSources, setIncomeSources] = useState([
    {
      type: "",
      monthlyIncome: 0,
      providerAddress: "",
      providerPhone: "",
    },
  ]);

  useEffect(() => {
    getProspectById(prospectId).then((data) => {
      if (data.incomeDetails !== null) {
        if (data.incomeDetails.employmentDetails !== null)
          setEmploymentDetails(data.incomeDetails.employmentDetails);
        if (data.incomeDetails.incomeSources !== null)
          setIncomeSources(data.incomeDetails.incomeSources);
      }
    });
  }, [prospectId]);

  const onChangeEmployment = (
    update: { name: string; value: any },
    index: number
  ) => {
    const { name, value } = update;
    const test = [...employmentDetails];
    test[index][name] = value;

    setEmploymentDetails(test);
  };
  const onChangeIncome = (
    update: { name: string; value: any },
    index: number
  ) => {
    const { name, value } = update;
    const test = [...incomeSources];
    test[index][name] = value;

    setIncomeSources(test);
  };

  const onSave = () => {
    updateProspect(prospectId, [
      {
        op: "replace",
        path: "/incomeDetails",
        value: {
          employmentDetails: employmentDetails,
          incomeSources: incomeSources,
        },
      },
    ]).then(() => {
      setDetailsTab("miscellaneous");
      alertSuccess("Prospect updated Successfully");
    });
  };
  return (
    <div className="w-full md:p-4 ">
      <div className="flex flex-col items-start self-center ">
        <div className="text-xl font-semibold text-gray-900 my-4">
          Please list employment from past five years & any other sources of
          income.
        </div>
        <div className="border-b-2 border-newGray-700">
          {employmentDetails.map((item, index) => (
            <MaterialForm
              className={`w-full grid grid-flow-row-dense grid-rows-1 grid-cols-1 md:grid-cols-4 my-2 ${
                employmentDetails.length === index + 1
                  ? ""
                  : "border-b-2 border-newGray-700"
              }`}
              data={item}
              onChange={(update: { name: string; value: any }) =>
                onChangeEmployment(update, index)
              }
              fieldClassName="px-2 my-2"
              renderArray={[
                {
                  name: "employer",
                  variant: "OUTLINED",
                },
                {
                  name: "position",
                  variant: "OUTLINED",
                },
                {
                  name: "fromDate",
                  inputType: "DATE",
                  variant: "OUTLINED",
                  label: "From",
                },
                {
                  name: "toDate",
                  inputType: "DATE",
                  variant: "OUTLINED",
                  label: "To",
                },
                {
                  name: "monthlyIncome",
                  inputType: "NUMBER",
                  variant: "OUTLINED",
                },
                {
                  name: "employerAddress",

                  variant: "OUTLINED",
                },
                {
                  name: "supervisorName",

                  variant: "OUTLINED",
                  label: "Supervisor's Name",
                },
                {
                  name: "supervisorPhone",
                  inputType: "PHONE",
                  variant: "OUTLINED",
                },
              ]}
            />
          ))}
          <button
            className="border w-max h-8 rounded text-sm px-2 py-1 bg-newBlue-500 text-newBlue-400 ml-2 my-4"
            onClick={() =>
              setEmploymentDetails([
                ...employmentDetails,
                {
                  employer: "",
                  position: "",
                  fromDate: "",
                  toDate: "",
                  monthlyIncome: 0,
                  employerAddress: "",
                  supervisorName: "",
                  supervisorPhone: "",
                },
              ])
            }
          >
            <div className="flex flex-row items-center ">
              <div>
                <AddIconBlue className="h-4 w-4" />
              </div>
              <div className="px-2 flex">
                <div className="font-medium">Add Previous Employment</div>
              </div>
            </div>
          </button>
        </div>
        <div className="">
          <div className="text-xl font-semibold text-gray-900 my-4">
            Other Income Sources
          </div>
          {incomeSources.map((item, index) => (
            <MaterialForm
              className={`w-full grid grid-flow-row-dense grid-rows-1 grid-cols-1 md:grid-cols-3 mt-3 ${
                incomeSources.length === index + 1
                  ? ""
                  : "border-b-2 border-newGray-700"
              } `}
              data={item}
              onChange={(update: { name: string; value: any }) =>
                onChangeIncome(update, index)
              }
              fieldClassName="px-2 my-2"
              renderArray={[
                {
                  name: "type",
                  variant: "OUTLINED",
                  label: "Income Type",
                },
                {
                  name: "monthlyIncome",
                  variant: "OUTLINED",
                  inputType: "NUMBER",
                },
                {
                  name: "providersPhone",
                  inputType: "PHONE",
                  variant: "OUTLINED",
                },
                {
                  name: "providerAddress",
                  variant: "OUTLINED",
                },
              ]}
            />
          ))}
          <button
            className="border w-max h-8 rounded text-sm px-2 py-1 bg-newBlue-500 text-newBlue-400 ml-2 my-4"
            onClick={() =>
              setIncomeSources([
                ...incomeSources,
                {
                  type: "",
                  monthlyIncome: 0,
                  providerAddress: "",
                  providerPhone: "",
                },
              ])
            }
          >
            <div className="flex flex-row items-center ">
              <div>
                <AddIconBlue className="h-4 w-4" />
              </div>
              <div className="px-2 flex">
                <div className="font-medium">Add Other Income Source</div>
              </div>
            </div>
          </button>
        </div>
        <div className="flex flex-row self-end mt-4 mr-4 ">
          <button
            className="text-base flex justify-center items-center px-4 py-2 rounded  font-medium cursor-pointer 
        bg-gray-300
        text-gray-800 duration-200 ease-in-out 
        transition"
            onClick={() => setCurrentApplicationTab("applicationListing")}
          >
            Cancel
          </button>
          <button
            className="text-base  ml-2  flex justify-center items-center px-4 py-2 rounded font-medium  
        bg-newBlue-400  w-40
        text-white border-newBlue-400
        border"
            onClick={onSave}
          >
            Save & Continue
          </button>
        </div>
      </div>
    </div>
  );
}
