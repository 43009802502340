import React from "react";

import AppsIcon from "@mui/icons-material/Apps";
import AssignmentIcon from "@mui/icons-material/Assignment";
import BuildIcon from "@mui/icons-material/Build";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import Close from "@mui/icons-material/Close";
import ExpandCircleDownOutlinedIcon from "@mui/icons-material/ExpandCircleDownOutlined";
import ForumIcon from "@mui/icons-material/Forum";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import ScheduleIcon from "@mui/icons-material/Schedule";
import SettingsIcon from "@mui/icons-material/Settings";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { useTheme } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import clsx from "clsx";
import { Link } from "raviger";

import activeUserStore from "../../store/ActiveUserStore";
import { conditionalArrayEntry } from "../../utils/ObjectUtills";
import { useMediaQuery } from "../../utils/useMediaQuery";
import { BuildingIcon } from "../clients/ClientAppIcons";
import ExtendedTicketForm from "../layout/ExtendedTicketForm";
import ExtendedTodo from "../layout/ExtendedTodo";
import { InvoiceIcon, Tick, TicketIcon } from "./AppIcons";
import NavBar from "./NavBar";
import GlobalComponents from "./core/GlobalComponents";
import Slideover from "./core/Slideover";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    padding: `${theme.spacing(0, 1)} !important`,
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: `${theme.spacing(3)} !important`,
  },
  appBar: {
    zIndex: 10 + 1, //Originally theme.zIndex.drawer + 1
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    zIndex: 10, // Manually Overriden
    flexShrink: 0,
    whiteSpace: "nowrap",
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    backgroundColor: "#12259D",
  },
  navOptions: {
    "padding-top": "8px",
    "padding-bottom": "8px",
    "padding-left": "16px",
    "padding-right": "8px",
    "justify-content": "flex-start",
    display: "flex",
  },
  drawerClose: {
    zIndex: 10,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: `${theme.spacing(7)} !important`,
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9),
    },
    backgroundColor: "#12259D",
  },
}));

export default function AppLayout({ children, isAdmin, isHelpSquad }) {
  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);

  // TODO: Merge the two states into one
  const [threeColumnMode, setThreeColumnMode] = React.useState();
  const [viewTicketSlideover, setViewTicketSlideover] = React.useState(false);

  const isMobile = useMediaQuery("(max-width: 640px)");

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  console.log("iconUrl", activeUserStore.getActiveUser().iconUrl);

  let link = document.querySelector("link[rel~='icon']");
  if (!link) {
    link = document.createElement("link");
    link.rel = "icon";
    document.getElementsByTagName("head")[0].appendChild(link);
  }
  link.href = activeUserStore.getActiveUser().iconUrl;

  let links = [
    { text: "Sales", icon: <TrendingUpIcon />, link: "/sales" },
    { text: "CRM", icon: <ForumIcon />, link: "/crm" },
    {
      text: "Service Requests",
      icon: <AssignmentIcon />,
      link: "/service_requests",
    },
    { text: "Scheduler", icon: <ScheduleIcon />, link: "/scheduler" },
    { text: "Associates", icon: <BuildIcon />, link: "/associates" },
    { text: "Clients", icon: <PeopleAltIcon />, link: "/clients" },
    {
      text: "Buildings",
      icon: <BuildingIcon />,
      link: "/buildings",
    },

    {
      text: "Suppliers",
      icon: <LocalShippingIcon />,
      link: "/suppliers",
    },
    {
      text: "Invoices",
      icon: <InvoiceIcon className="h-6 w-6" />,
      link: "/invoice",
    },
  ];
  if (isHelpSquad)
    links = [
      {
        text: "Dashboard",
        icon: <AppsIcon />,
        link: "/",
      },
      ...links,
    ];

  return (
    <div className={"flex bg-gray-300 h-full w-full"}>
      <NavBar
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
        menuClassName={clsx(classes.menuButton, {
          [classes.hide]: open,
        })}
        onClick={handleDrawerOpen}
      >
        {(!isMobile || open) && (
          <Drawer
            variant="permanent"
            className={clsx(classes.drawer, {
              [classes.drawerOpen]: open,
              [classes.drawerClose]: !open,
            })}
            classes={{
              paper: clsx({
                [classes.drawerOpen]: open,
                [classes.drawerClose]: !open,
              }),
            }}
          >
            <div className={classes.toolbar}>
              <IconButton
                className={"text-white"}
                onClick={handleDrawerClose}
                size="large"
              >
                {theme.direction === "rtl" ? (
                  <ChevronRightIcon />
                ) : (
                  <ChevronLeftIcon />
                )}
              </IconButton>
            </div>
            <Divider />
            <List>
              {links.map((navItem, index) => (
                <Link
                  key={navItem.text}
                  href={navItem.link}
                  onClick={handleDrawerClose}
                >
                  <ListItem button className={classes.navOptions}>
                    <ListItemIcon className={"text-white"}>
                      {navItem.icon}
                    </ListItemIcon>
                    <ListItemText
                      primary={navItem.text}
                      className={"text-white"}
                    />
                  </ListItem>
                </Link>
              ))}
            </List>
            <Divider />
            {isAdmin && (
              <List>
                {[
                  {
                    text: "Settings",
                    icon: <SettingsIcon />,
                    link: "/settings",
                  },
                ].map((navItem, index) => (
                  <Link
                    key={navItem.text}
                    href={navItem.link}
                    onClick={handleDrawerClose}
                  >
                    <ListItem button className={classes.navOptions}>
                      <ListItemIcon className="text-white">
                        {navItem.icon}
                      </ListItemIcon>
                      <ListItemText
                        primary={navItem.text}
                        className="text-white"
                      />
                    </ListItem>
                  </Link>
                ))}
              </List>
            )}
            {/* Link at the Bottom */}
            {isMobile && (
              <ListItem
                className={"absolute bottom-4 w-full cursor-pointer"}
                onClick={() => {
                  handleDrawerClose();
                  setViewTicketSlideover((current) => !current);
                }}
              >
                <ListItemIcon className="text-white">
                  <TicketIcon className="h-6 w-6" />
                </ListItemIcon>
                <ListItemText
                  primary={"Create Ticket"}
                  className="text-white"
                />
              </ListItem>
            )}
          </Drawer>
        )}
      </NavBar>
      <main className={"flex-1 flex flex-col p-2 w-full overflow-auto"}>
        <div className={classes.toolbar} />
        <div className="flex h-full w-full">
          <div
            className={threeColumnMode ? "w-full md:w-5/6" : "w-full h-full"}
          >
            {children}
          </div>
          <div className="hidden md:flex">
            {threeColumnMode && (
              <div className="w-80">
                {threeColumnMode === "TICKET" ? (
                  <ExtendedTicketForm onClose={() => setThreeColumnMode()} />
                ) : threeColumnMode === "TODO" ? (
                  <ExtendedTodo />
                ) : (
                  <></>
                )}
              </div>
            )}
            <Slideover
              className="z-40"
              open={viewTicketSlideover}
              onClose={() => setViewTicketSlideover(false)}
            >
              <div className="flex justify-end mt-32">
                <button onClick={() => setViewTicketSlideover(false)}>
                  <ExpandCircleDownOutlinedIcon
                    className="h-8 w-8 text-gray-700"
                    aria-hidden="true"
                  />
                </button>
              </div>
              <ExtendedTicketForm
                className="w-full px-4"
                onClose={() => setViewTicketSlideover(false)}
              />
            </Slideover>
            <div className="flex flex-col items-center gap-2 m-2">
              {[
                {
                  icon: TicketIcon,
                  action: () => {
                    setThreeColumnMode("TICKET");
                  },
                  label: "Ticket",
                  mode: "TICKET",
                },
                {
                  icon: Tick,
                  action: () => {
                    setThreeColumnMode("TODO");
                  },
                  label: "Todo",
                  mode: "TODO",
                },
                ...conditionalArrayEntry(!!threeColumnMode, {
                  icon: Close,
                  action: () => setThreeColumnMode(),
                  label: "Close",
                }),
              ].map((item) => (
                <div
                  className="flex flex-col items-center gap-2 m-2"
                  key={item.label}
                >
                  <div
                    className={
                      threeColumnMode === item.mode
                        ? "flex items-center justify-center w-12 h-12 rounded-full bg-green-500 text-gray-100 hover:bg-green-100 cursor-pointer"
                        : "flex items-center justify-center w-12 h-12 rounded-full bg-green-200 text-gray-600 hover:bg-green-100 cursor-pointer"
                    }
                    onClick={item.action}
                  >
                    <item.icon className="w-6 h-6" />
                  </div>
                  <div className="text-xs text-gray-800">{item.label}</div>
                </div>
              ))}
            </div>
          </div>
        </div>
        <GlobalComponents />
      </main>
    </div>
  );
}
