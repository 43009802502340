import { useEffect, useState } from "react";

import {
  alertError,
  alertInfo,
  alertSuccess,
} from "../../../../actions/AlertActions";
import {
  fileUpload,
  getBuildingUnits,
  getUnitById,
  newBuildingGet,
  updateBuilding,
  updateUnit,
} from "../../../../api/Api";
import { ImagePlaceholder } from "../../../../components/clients/ClientAppIcons";
import {
  CrossIcon,
  PetsAllowedIcon,
  PetsNotAllowedIcon,
  Tick,
} from "../../../../components/common/AppIcons";
import MaterialForm from "../../../../components/common/form/MaterialForm";
import { deepUpdate } from "../../../../utils/StateUtils";
import { amenitiesForm, uploadEncodedFile } from "../helper";

type UnitDetailsTabProps = {
  unitId: number;
  buildingId: number;
  onBackClick: React.Dispatch<React.SetStateAction<string>>;
};

export default function UnitDetailsTab({
  unitId,
  buildingId,
  onBackClick,
}: UnitDetailsTabProps) {
  const [generalInfo, setGeneralInfo] = useState({
    unitNumber: "",
    marketRent: 0,
    unitSize: 0,
    beds: 0,
    bath: 0,
    description: "",
    overridePetPolicy: false,
    overrideFeePolicy: false,
  });

  const [selectAll, setSelectAll] = useState({
    allSelected: false,
  });
  const [imageUrls, setImageUrls] = useState<string[]>([]);

  const [unitOptions, setUnitOptions] = useState([]);
  const [propertyAmenities, setPropertyAmenities] = useState({
    BIKE_PATHS: false,
    BOATING: false,
    CLUBHOUSE: false,
    CONTROLLED_ACCESS: false,
    COURTYARD: false,
    FITNESS_CENTER: false,
    GOLF_COURSE: false,
    LAUNDRY_FACILITIES: false,
    "PLAYGROUND/PARK": false,
    POOL: false,
    PUBLIC_TRANSPORTATION: false,
    RESERVED_PARKING: false,
    "SPA/JACUZZI": false,
    STORAGE: false,
    TENNIS_COURT: false,
  });

  const [petPolicy, setPetPolicy] = useState({
    largeDogs: false,
    smallDogs: false,
    cats: false,
    description: "",
  });

  const [feePolicy, setFeePolicy] = useState({
    oneTimeFee: "",
    monthlyFee: "",
  });

  const [propertySelectAll, setPropertySelectAll] = useState({
    allSelected: false,
  });

  const onChangePropertyAmenities = (update: {
    name: string;
    value: string;
  }) => {
    const { name, value } = update;
    setPropertyAmenities((data) => deepUpdate(name, value, data));
    setPropertySelectAll({ allSelected: false });
  };

  const [type, setType] = useState("petsAllowed");

  const onChangePetPolicy = (update: { name: string; value: string }) => {
    const { name, value } = update;
    setPetPolicy((data) => deepUpdate(name, value, data));
  };
  const onChangeFeePolicy = (update: { name: string; value: string }) => {
    const { name, value } = update;
    setFeePolicy((data) => deepUpdate(name, value, data));
  };

  const [amenities, setAmenities] = useState({
    AIR_CONDITIONING: false,
    "BALCONY/PATIO": false,
    "CABLE/SATELLITE_TV": false,
    CARPETED_FLOORS: false,
    CEILING_FAN: false,
    DISHWASHER: false,
    ELECTRIC_RANGE: false,
    FIREPLACE: false,
    FURNISHED: false,
    GARAGE: false,
    GARBAGE_DISPOSAL: false,
    GAS_RANGE: false,
    HARDWOOD_FLOORS: false,
    "HIGH-SPEED_INTERNET": false,
    INTRUSION_ALARM: false,
    MICROWAVE: false,
    PETS_ALLOWED: false,
    REFRIGERATOR: false,
    UTILITIES_PAID: false,
    VAULTED_CEILINGS: false,
    "WALK-IN_CLOSETS": false,
    "WASHER/DRYER_HK-UP": false,
    "WASHER/DRYER_IN_UNIT": false,
    WATERFRONT_PROPERTY: false,
    WHEELCHAIR_ACCESS: false,
  });

  useEffect(() => {
    newBuildingGet(buildingId).then((data) => {
      if (data.profile !== null) {
        setPropertyImageUrls(data.profile.photos ?? []);
        if (data.profile.amenities !== null)
          setPropertyAmenities(data.profile.amenities);
      }
    });
  }, [buildingId]);

  useEffect(() => {
    newBuildingGet(buildingId).then((building) => {
      if (building.profile.petPolicy !== null) {
        setType(
          building.profile.petPolicy.petsAllowed
            ? "petsAllowed"
            : "petsNotAllowed"
        );
        setPetPolicy(building.profile.petPolicy);
      }
      if (building.profile.feePolicy !== null)
        setFeePolicy(building.profile.feePolicy);
    });
    getUnitById(unitId)
      .then((data) => {
        setGeneralInfo({
          unitNumber: data.unitNumber,
          marketRent: data.unitDetails.marketRent,
          unitSize: data.unitDetails.unitSize,
          beds: data.unitDetails.beds,
          bath: data.unitDetails.bath,
          description: data.unitDetails.description,
          overridePetPolicy: data.unitDetails.overridePetPolicy,
          overrideFeePolicy: data.unitDetails.overrideFeePolicy,
        });
        setImageUrls(data.photos !== null ? data.photos : []);
        setAmenities(data.unitDetails.amenities);
        if (data.unitDetails.petPolicy !== null) {
          setType(
            data.unitDetails.petPolicy.petsAllowed
              ? "petsAllowed"
              : "petsNotAllowed"
          );
          setPetPolicy(data.unitDetails.petPolicy);
        }
        if (data.unitDetails.feePolicy !== null)
          setFeePolicy(data.unitDetails.feePolicy);
      })
      .catch((_) =>
        alertError("Couldn't Connect to Server, Check your Connection")
      );
    getBuildingUnits(buildingId).then((data) => {
      setUnitOptions((_) =>
        data.value.map((item: string) => ({ label: item, value: item }))
      );
    });
  }, [unitId, buildingId]);

  const onChangeInfo = (update: { name: string; value: string }) => {
    const { name, value } = update;
    setGeneralInfo((data) => deepUpdate(name, value, data));
  };

  const onChangeAmenities = (update: { name: string; value: string }) => {
    const { name, value } = update;
    setAmenities((data) => deepUpdate(name, value, data));
    setSelectAll({ allSelected: false });
  };
  const [propertyImageUrls, setPropertyImageUrls] = useState<string[]>([]);

  const onChangePropertySelectAll = (update: {
    name: string;
    value: boolean;
  }) => {
    const { name, value } = update;

    setPropertySelectAll((data) => deepUpdate(name, value, data));

    setPropertyAmenities({
      BIKE_PATHS: value,
      BOATING: value,
      CLUBHOUSE: value,
      CONTROLLED_ACCESS: value,
      COURTYARD: value,
      FITNESS_CENTER: value,
      GOLF_COURSE: value,
      LAUNDRY_FACILITIES: value,
      "PLAYGROUND/PARK": value,
      POOL: value,
      PUBLIC_TRANSPORTATION: value,
      RESERVED_PARKING: value,
      "SPA/JACUZZI": value,
      STORAGE: value,
      TENNIS_COURT: value,
    });
  };

  const onChangeSelectAll = (update: { name: string; value: boolean }) => {
    const { name, value } = update;

    setSelectAll((data) => deepUpdate(name, value, data));

    setAmenities({
      AIR_CONDITIONING: value,
      "BALCONY/PATIO": value,
      "CABLE/SATELLITE_TV": value,
      CARPETED_FLOORS: value,
      CEILING_FAN: value,
      DISHWASHER: value,
      ELECTRIC_RANGE: value,
      FIREPLACE: value,
      FURNISHED: value,
      GARAGE: value,
      GARBAGE_DISPOSAL: value,
      GAS_RANGE: value,
      HARDWOOD_FLOORS: value,
      "HIGH-SPEED_INTERNET": value,
      INTRUSION_ALARM: value,
      MICROWAVE: value,
      PETS_ALLOWED: value,
      REFRIGERATOR: value,
      UTILITIES_PAID: value,
      VAULTED_CEILINGS: value,
      "WALK-IN_CLOSETS": value,
      "WASHER/DRYER_HK-UP": value,
      "WASHER/DRYER_IN_UNIT": value,
      WATERFRONT_PROPERTY: value,
      WHEELCHAIR_ACCESS: value,
    });
  };

  const uploadImage = (data: any) => {
    alertInfo("Uploading Image");
    fileUpload(data)
      .then((res) => {
        console.log("Success!", res.value);
        setImageUrls([...imageUrls, res.value]);
        alertInfo("Image Uploaded, Please save before leaving this page");
      })
      .catch((failure) => {
        console.log("Failure", failure);
      });
  };
  const onDelete = (image: string) => {
    const filteredImages = imageUrls.filter((item) => item !== image);
    setImageUrls(filteredImages);
  };

  const handleChooseImage = (event: { target: { files: any } }) => {
    let files = event.target.files;
    Array.from(files).forEach((file) => {
      console.log("MIME:" + file.type);
      let reader = new FileReader();
      reader.onload = uploadEncodedFile(uploadImage, file);
      reader.readAsDataURL(file);
    });
  };

  const uploadPropertyImage = (data: any) =>
    fileUpload(data)
      .then((res) => {
        console.log("Success!", res.value);
        setPropertyImageUrls([...propertyImageUrls, res.value]);
      })
      .catch((failure) => {
        console.log("Failure", failure);
      });

  const onDeletePropertyImage = (image: string) => {
    const filteredImages = imageUrls.filter((item) => item !== image);
    setPropertyImageUrls(filteredImages);
  };
  const handleChoosePropertyImage = (event: { target: { files: any } }) => {
    alertInfo("Uploading image");
    let files = event.target.files;
    Array.from(files).forEach((file) => {
      console.log("MIME:" + file.type);
      let reader = new FileReader();
      reader.onload = uploadEncodedFile(uploadPropertyImage, file);
      reader.readAsDataURL(file);
    });
  };

  return (
    <div className="md:w-3/4 p-4 ">
      <div className="flex flex-col items-center md:items-start self-center ">
        <div className="text-2xl font-semibold text-black">General Info</div>
        <div className="text-lg font-medium text-black mt-10">
          Add Unit Details
        </div>
        <div className="mt-4 pb-4 border-b">
          <MaterialForm
            className="w-full flex flex-col md:flex-row  gap-4"
            data={generalInfo}
            onChange={onChangeInfo}
            fieldClassName=""
            renderArray={[
              {
                name: "unitNumber",
                inputType: unitOptions.length > 0 ? "SELECT" : "TEXT",
                options: unitOptions,
                variant: "OUTLINED",
              },
              {
                name: "marketRent",
                inputType: "NUMBER",
                variant: "OUTLINED",
                label: "Market Rent($)",
              },
              {
                name: "unitSize",
                inputType: "NUMBER",
                variant: "OUTLINED",
                label: "Unit Size (sq.ft)",
              },
              { name: "beds", inputType: "NUMBER", variant: "OUTLINED" },
              { name: "bath", inputType: "NUMBER", variant: "OUTLINED" },
            ]}
          />
          <MaterialForm
            className=" mt-6 "
            data={generalInfo}
            onChange={onChangeInfo}
            fieldClassName="w-full"
            renderArray={[
              { name: "description", variant: "OUTLINED", inputType: "AREA" },
            ]}
          />

          <MaterialForm
            className=" mt-6"
            data={generalInfo}
            onChange={onChangeInfo}
            fieldClassName=""
            renderArray={[
              {
                name: "overridePetPolicy",
                inputType: "CHECKBOX",
                variant: "OUTLINED",
              },
            ]}
          />
          {generalInfo.overridePetPolicy && (
            <div className="flex flex-col w-full justify-center items-center mt-10">
              <div className="text-xl font-semibold mb-4">Pet Policy</div>
              <div className="flex flex-col md:flex-row justify-start items-center gap-3">
                <button
                  className="flex flex-col justify-center items-center relative p-4 bg-newGray-1400 w-60 h-32"
                  onClick={() => setType("petsAllowed")}
                >
                  <PetsAllowedIcon className="h-12 w-12" />
                  <div className="text-sm text-newGray-800 font-normal">
                    Pets Allowed
                  </div>
                  {type === "petsAllowed" && (
                    <div className="flex absolute top-2 right-2">
                      <div className="flex items-center justify-center bg-green-400 w-6 h-6  rounded-full">
                        <Tick />
                      </div>
                    </div>
                  )}
                </button>
                <button
                  className="flex flex-col justify-center relative  items-center py-4 bg-newGray-1400 w-60 h-32"
                  onClick={() => {
                    setType("petsNotAllowed");
                    setPetPolicy({
                      largeDogs: false,
                      smallDogs: false,
                      cats: false,
                      description: petPolicy.description,
                    });
                  }}
                >
                  <PetsNotAllowedIcon className="h-12 w-12" />
                  <div className="text-sm text-newGray-800 font-normal">
                    Pets Not Allowed
                  </div>

                  {type === "petsNotAllowed" && (
                    <div className="flex absolute top-2 right-2">
                      <div className="flex items-center justify-center bg-green-400 w-6 h-6  rounded-full">
                        <Tick />
                      </div>
                    </div>
                  )}
                </button>
              </div>
              {type === "petsAllowed" && (
                <div className="mt-3 w-1/2">
                  <MaterialForm
                    className="w-full grid grid-flow-row-dense  grid-rows-1 md:grid-cols-3 mt-3"
                    data={petPolicy}
                    onChange={onChangePetPolicy}
                    fieldClassName="mr-2"
                    renderArray={[
                      {
                        name: "largeDogs",
                        inputType: "CHECKBOX",
                        label: "Large Dogs",
                      },
                      {
                        name: "smallDogs",
                        inputType: "CHECKBOX",
                        label: "Small Dogs",
                      },
                      {
                        name: "cats",
                        inputType: "CHECKBOX",
                        label: "Cats",
                      },
                    ]}
                  />
                </div>
              )}

              <MaterialForm
                className="mt-6 w-full md:w-1/2"
                data={petPolicy}
                onChange={onChangePetPolicy}
                fieldClassName="w-full"
                renderArray={[
                  {
                    name: "description",
                    variant: "OUTLINED",
                    inputType: "AREA",
                  },
                ]}
              />
            </div>
          )}
          <MaterialForm
            className=" mt-2"
            data={generalInfo}
            onChange={onChangeInfo}
            fieldClassName=" mr-20"
            renderArray={[
              {
                name: "overrideFeePolicy",
                inputType: "CHECKBOX",
                variant: "OUTLINED",
              },
            ]}
          />
          {generalInfo.overrideFeePolicy && (
            <MaterialForm
              className="flex justify-evenly w-full  mt-3 flex-col md:flex-row gap-3"
              data={feePolicy}
              onChange={onChangeFeePolicy}
              fieldClassName="mr-2"
              renderArray={[
                {
                  name: "oneTimeFee",
                  variant: "OUTLINED",
                  inputType: "NUMBER",
                  label: "One Time Fee($)",
                },
                {
                  name: "monthlyPetFee",
                  variant: "OUTLINED",
                  inputType: "NUMBER",
                  label: "Monthly Pet Fee($)",
                },
              ]}
            />
          )}
        </div>
      </div>
      <div className="my-10 pb-4 border-b w-full">
        <div className="text-xl font-semibold text-black text-center md:text-left">
          Upload Unit Photos
        </div>
        <div className="grid grid-flow-row-dense grid-rows-1 grid-cols-2 gap-3 md:grid-cols-4 mt-3">
          {imageUrls?.map((item) => {
            return (
              <div className="flex rounded h-28 w-28 md:h-32 md:w-32 justify-center items-center overflow-hidden bg-newGray-700 relative">
                {item.match(/\.(jpeg|jpg|gif|png|webp)$/) ? (
                  <img className="object-scale-down" src={item} alt="" />
                ) : (
                  <video controls style={{ maxWidth: "100%" }}>
                    <source src={item} type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>
                )}
                <button
                  className="absolute top-2 right-2"
                  onClick={() => onDelete(item)}
                >
                  <CrossIcon />
                </button>
              </div>
            );
          })}
          <div className="flex rounded h-28 w-28 md:h-32 md:w-32 justify-center items-center overflow-hidden bg-newGray-700">
            <div className="">
              <label className="flex w-full cursor-pointer  items-center justify-center rounded-md border-2 border-dashed border-gray-200 p-2  hover:border-primary-300">
                <div className="space-y-1 text-center">
                  <div className="mx-auto inline-flex h-10 w-10 items-center justify-center rounded-full bg-gray-100">
                    <ImagePlaceholder />
                  </div>
                  <div className=" text-xs text-gray-600 font-medium text-primary-500 hover:text-primary-700">
                    Upload images/videos of the Unit
                  </div>
                </div>
                <input
                  id="upload_image"
                  type="file"
                  className="hidden"
                  onChange={handleChooseImage}
                />
              </label>
            </div>
          </div>
        </div>
        <div className="text-xl font-semibold text-black mt-8 text-center md:text-left">
          Upload Property Photos
        </div>
        <div className="grid grid-flow-row-dense grid-rows-1 grid-cols-2 gap-3 md:grid-cols-4 mt-3">
          {propertyImageUrls?.map((item) => {
            return (
              <div className="flex rounded h-28 w-28 md:h-32 md:w-32 justify-center items-center overflow-hidden bg-newGray-700 relative">
                {item.match(/\.(jpeg|jpg|gif|png|webp)$/) ? (
                  <img className="object-scale-down" src={item} alt="" />
                ) : (
                  <video controls style={{ maxWidth: "100%" }}>
                    <source src={item} type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>
                )}
                <button
                  className="absolute top-2 right-2"
                  onClick={() => onDeletePropertyImage(item)}
                >
                  <CrossIcon />
                </button>
              </div>
            );
          })}
          <div className="flex rounded h-28 w-28 md:h-32 md:w-32 justify-center items-center overflow-hidden bg-newGray-700">
            <div className="">
              <label className="flex w-full cursor-pointer  items-center justify-center rounded-md border-2 border-dashed border-gray-200 p-2  hover:border-primary-300">
                <div className="space-y-1 text-center">
                  <div className="mx-auto inline-flex h-10 w-10 items-center justify-center rounded-full bg-gray-100">
                    <ImagePlaceholder />
                  </div>
                  <div className=" text-xs text-gray-600 font-medium text-primary-500 hover:text-primary-700">
                    Upload images/videos of the property
                  </div>
                </div>
                <input
                  id="upload_image"
                  type="file"
                  className="hidden"
                  onChange={handleChoosePropertyImage}
                />
              </label>
            </div>
          </div>
        </div>
      </div>
      <div className="text-xl font-semibold text-black text-center md:text-left">Unit Amenities</div>
      <div className="">
        <MaterialForm
          className="w-full grid grid-flow-row-dense grid-rows-1 grid-cols-1 md:grid-cols-3 mt-3"
          data={selectAll}
          onChange={onChangeSelectAll}
          fieldClassName="mr-2 p-4"
          renderArray={[
            {
              name: "allSelected",
              inputType: "CHECKBOX",
              label: "Select All",
            },
          ]}
        />
      </div>
      <div className="mt-4 w-full">
        <MaterialForm
          className="w-full grid grid-flow-row-dense grid-rows-1  grid-cols-1 md:grid-cols-3 mt-3"
          data={amenities}
          onChange={onChangeAmenities}
          fieldClassName="mx-2"
          renderArray={amenitiesForm()}
        />
      </div>
      <div className="text-xl font-semibold text-black mt-8 text-center md:text-left">
        Property Amenities
      </div>
      <div className="">
        <MaterialForm
          className="w-full grid grid-flow-row-dense grid-rows-1  grid-cols-1 md:grid-cols-3 mt-3"
          data={propertySelectAll}
          onChange={onChangePropertySelectAll}
          fieldClassName="mr-2 p-4"
          renderArray={[
            {
              name: "allSelected",
              inputType: "CHECKBOX",
              label: "Select All",
            },
          ]}
        />

        <div className="mt-4">
          <MaterialForm
            className="w-full grid grid-flow-row-dense grid-rows-1 grid-cols-1 md:grid-cols-3 mt-3"
            data={propertyAmenities}
            onChange={onChangePropertyAmenities}
            fieldClassName="mx-2"
            renderArray={[
              {
                name: "BIKE_PATHS",
                inputType: "CHECKBOX",
                label: "Bike Paths",
              },
              {
                name: "BOATING",
                inputType: "CHECKBOX",
                label: "Boating",
              },
              {
                name: "CLUBHOUSE",
                inputType: "CHECKBOX",
                label: "Clubhouse",
              },
              {
                name: "CONTROLLED_ACCESS",
                inputType: "CHECKBOX",
                label: "Controlled Access",
              },
              {
                name: "COURTYARD",
                inputType: "CHECKBOX",
                label: "Courtyard",
              },
              {
                name: "FITNESS_CENTER",
                inputType: "CHECKBOX",
                label: "Fitness Center",
              },
              {
                name: "GOLF_COURSE",
                inputType: "CHECKBOX",
                label: "Golf Course",
              },
              {
                name: "LAUNDRY_FACILITIES",
                inputType: "CHECKBOX",
                label: "Laundry Facilities",
              },
              {
                name: "PLAYGROUND/PARK",
                inputType: "CHECKBOX",
                label: "Playground/Park",
              },
              {
                name: "POOL",
                inputType: "CHECKBOX",
                label: "Pool",
              },
              {
                name: "PUBLIC_TRANSPORTATION",
                inputType: "CHECKBOX",
                label: "Public Transportation",
              },
              {
                name: "RESERVED_PARKING",
                inputType: "CHECKBOX",
                label: "Reserved Parking",
              },
              {
                name: "SPA/JACUZZI",
                inputType: "CHECKBOX",
                label: "Spa/Jacuzzi",
              },
              {
                name: "STORAGE",
                inputType: "CHECKBOX",
                label: "Storage",
              },
              {
                name: "TENNIS_COURT",
                inputType: "CHECKBOX",
                label: "Tennis Court",
              },
            ]}
          />
        </div>
      </div>
      <div className="flex flex-row-reverse  md:self-end mt-4 md:mr-4">
        <button
          className="text-base  ml-2  flex justify-center items-center px-4 py-2 rounded font-medium  
            bg-newBlue-400  w-40
            text-white border-newBlue-400
            border"
          onClick={() => {
            updateUnit(unitId, [
              {
                op: "replace",
                path: "/photos",
                value: imageUrls,
              },
              {
                op: "replace",
                path: "/unitDetails/amenities",
                value: amenities,
              },
              {
                op: "replace",
                path: "/unitNumber",
                value: generalInfo.unitNumber,
              },
              {
                op: "replace",
                path: "/unitDetails/marketRent",
                value: generalInfo.marketRent,
              },
              {
                op: "replace",
                path: "/unitDetails/unitSize",
                value: generalInfo.unitSize,
              },
              {
                op: "replace",
                path: "/unitDetails/beds",
                value: generalInfo.beds,
              },
              {
                op: "replace",
                path: "/unitDetails/bath",
                value: generalInfo.bath,
              },
              {
                op: "replace",
                path: "/unitDetails/description",
                value: generalInfo.description,
              },
              {
                op: "replace",
                path: "/unitDetails/overrideFeePolicy",
                value: generalInfo.overrideFeePolicy,
              },
              {
                op: "replace",
                path: "/unitDetails/overridePetPolicy",
                value: generalInfo.overridePetPolicy,
              },
              {
                op: "replace",
                path: "/unitDetails/petPolicy",
                value: {
                  petsAllowed: type === "petsAllowed",
                  largeDogs: petPolicy.largeDogs,
                  smallDogs: petPolicy.smallDogs,
                  cat: petPolicy.cats,
                  description: petPolicy.description,
                },
              },
              {
                op: "replace",
                path: "/unitDetails/feePolicy",
                value: feePolicy,
              },
            ])
              .then(() => {
                updateBuilding(buildingId, [
                  {
                    op: "replace",
                    path: "/profile/photos",
                    value: propertyImageUrls,
                  },
                  {
                    op: "replace",
                    path: "/profile/amenities",
                    value: propertyAmenities,
                  },
                ])
                  .then(() => {
                    alertSuccess("Successfully saved Unit");
                  })
                  .catch(() => {
                    alertError("Error while saving photos");
                  });
              })
              .catch(() => {
                alertError("Error while saving unit");
              });
          }}
        >
          Save
        </button>
        <button
          className="text-base flex justify-center items-center px-4 py-2 rounded  font-medium cursor-pointer 
            bg-gray-300
            text-gray-800 duration-200 ease-in-out 
            transition"
          onClick={() => onBackClick("unitListing")}
        >
          Cancel
        </button>
      </div>
    </div>
  );
}
