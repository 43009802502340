import React, { useCallback, useEffect, useRef, useState } from "react";

import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { Button, Menu, MenuItem, TextField } from "@mui/material";
import { Typography } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import debounce from "lodash.debounce";
import moment from "moment";
import { type } from "os";

import {
  alertError,
  alertInfo,
  alertSuccess,
  alertSuccessWithCustomTime,
} from "../../actions/AlertActions";
import { useAppActions } from "../../actions/AppActions";
import {
  connectToQuickBooks,
  createEstimate,
  createInvoice,
  estimateAccept,
  getBillingEmails,
  getContactsForTicketId,
  getEstimate,
  qbGetCustomers,
  sendEstimatePdf,
  updateEstimate,
  getCurrentUser,
  getMyVendor,
  downloadEstimate,
} from "../../api/Api";
import activeUserStore from "../../store/ActiveUserStore";
import { deepUpdate } from "../../utils/StateUtils";
import { estimateAddress, renderPhone } from "../../utils/StringUtils";
import RenderAddressComponent from "../clients/RenderAddressComponent";
import { DocumentIcon, LoadingIcon2 } from "../common/AppIcons";
import MaterialModal from "../common/MaterialModal";
import MaterialTable from "../common/MaterialTable";
import Pagination from "../common/Pagination";
import { make as MaterialForm } from "../common/form/MaterialForm.bs";
import CustomFormatDatePicker from "../scheduler/CustomFormatDatePicker";
import {
  COL_INDEX,
  checkData,
  checkEstimateAcceptData,
  checkPayloadValidity,
  convertToEstimateData,
  convertToPayload,
} from "./EstimateFormUtils";
import EstimateTableNew from "./EstimateTableNew";
import InvoiceForm from "./InvoiceForm";
import MarkAsAcceptedModal from "./MarkAsAcceptedModal";
import { estimateStatusFormatted } from "./NewKanbanBoard";
import ListInvoices from "./components/ListInvoices";

// import { encode } from "base-64";
// import { addDays, getISODateString } from "../../utils/DateUtils";
// import moment from "moment";
// import pdfMake from "pdfmake/build/pdfmake";
// import { Height } from "@mui/icons-material";
// import jsPDF from "jspdf";
// import { make as MaterialForm } from "../common/form/MaterialForm.bs";

const headings = [
  "PRODUCT",
  "DESCRIPTION",
  "QTY",
  "RATE",
  "AMOUNT",
  "ACCEPTED BY",
  "ACCEPTED ON",
];

const new_row = () => {
  return ["", "", "0", "0", "0", "0", Number(new Date())];
};

export const fetchTicketContacts = (ticketId, setTicketContacts) =>
  getContactsForTicketId(ticketId)
    .then((contacts) => {
      setTicketContacts(contacts);
    })
    .catch((_error) => {
      alertError("Sorry! Error getting contacts.");
    });

export default function EstimateForm({
  ticketData,
  setShowModal,
  kanbanScreen = false,
  onSuccessCB,
  acceptEstimateCB = undefined,
  refreshCB,
  isExternal = false,
}) {
  const { withConfirmation } = useAppActions();
  const [ticketContacts, setTicketContacts] = useState([]);
  const [showContactModal, setShowContactModal] = useState(false);
  const [openQuickModal, setOpenQuickModal] = useState({
    url: "",
    open: false,
  });
  const [qbCustomers, setQBCustomers] = useState();
  const [newQBCustomer, setNewQBCustomer] = useState(false);
  const [invoiceCreation, setInvoiceCreation] = useState(false);
  const [showInvoice, setShowInvoice] = useState(false);
  const [listInvoices, setShowListInvoices] = useState(false);
  const [selectedInvoice, setSelectedInvoice] = useState({
    ticketId: 0,
    estimateId: 0,
    invoiceId: 0,
  });

  const [invoicedLines, setInvoicedLines] = useState([]);
  const [markCompletedModal, setMarkCompletedModal] = useState(false);
  const [markCompletedData, setMarkCompletedData] = useState({});
  const [data, setData] = useState({
    estimate: {},
    customer: ticketData?.clientName || "",
    address: estimateAddress(ticketData),
  });
  const [searchText, setSearchText] = useState("");
  const [warning, setWarning] = useState({ name_not_filled: false });
  const [partialInvoice, setPartialInvoice] = useState(false);
  const [selectedLines, setSelectedLines] = useState([]);
  const [selectedContacts, setSelectedContacts] = useState([]);
  const [selectedBillingEmail, setSelectedBillingEmail] = useState({
    billingEmail: "",
  });
  const [selectBillingEmailForm, setSelectBillingEmailForm] = useState([
    {
      name: "billingEmail",
      inputType: "SELECT",
      options: [],
      defaultValue: "",
    },
  ]);
  const [customerQBId, setCustomerQbId] = useState(null);
  const [QBEstimateId, setQBEstimateId] = useState(ticketData?.estimateId);
  const [displayMultiSelect, setDisplayMultiSelect] = useState(false);
  const [client_data, set_client_data] = useState({
    name: "",
    date: new Date().toISOString().split("T")[0],
    acceptedContactId: "",
  });
  const [acceptedByOptions, setAcceptedByOptions] = useState({
    options: [],
    defaultValue: "",
  });

  const [estimateItems, setEstimateItems] = useState({
    Accepted: { lines: [], total: 0 },
    Rejected: { lines: [], total: 0 },
    "Change Order": { lines: [], total: 0 },
    Unsent: { lines: [], total: 0 },
    Sent: { lines: [], total: 0 },
  });

  const [estimateChangeDetect, setEstimateChangeDetect] = useState(false);
  const [kanbanScreenReset, setKanbanScreenReset] = useState(kanbanScreen);
  const [contactScreenSMS, setContactScreenSMS] = useState(false);
  const [vendorName, setVendorName] = useState("");

  useEffect(() => {
    getMyVendor().then((data) => {
      setVendorName(data.name);
    });
  }, []);

  useEffect(() => {
    setData((current) => {
      return { ...current, address: estimateAddress(ticketData) };
    });
  }, [ticketData]);

  const ACCESS_TOKEN = "ACCESS_TOKEN";
  const headers = new Headers({
    "Content-Type": "application/pdf",
  });
  if (localStorage.getItem(ACCESS_TOKEN)) {
    headers.append(
      "Authorization",
      "Bearer " + localStorage.getItem(ACCESS_TOKEN)
    );
  }

  const downloadPDF = (id) => {
    alertInfo("Downloading...");
    fetch(`${process.env.REACT_APP_API_BASE_URL}/estimate/${id}/download`, {
      method: "GET",
      headers: headers,
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.blob();
      })
      .then((blob) => {
        // Create a URL for the blob
        const url = window.URL.createObjectURL(new Blob([blob]));
        // Create a temporary link element
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `Estimate_#${id}.pdf`);

        // Trigger the download
        document.body.appendChild(link);
        link.click();

        // Clean up
        link.parentNode.removeChild(link);
        alertSuccess("Estimate downloaded successfully");
      })
      .catch((error) => {
        console.error("There was a problem with your fetch operation:", error);
      });
  };

  useEffect(() => {
    if (contactScreenSMS) {
      let addedIds = selectedContacts;
      let status = false;
      addedIds.map((item, index) => {
        if (!status) {
          let contactObj = ticketContacts.find((contact) => {
            return Number(contact.contactId) === Number(item);
          });
          if (!contactObj.phone) {
            alertInfo(
              "SMS will only be sent to Contacts with a Phone Number saved"
            );
            status = true;
          }
        }
      });
    }
  }, [contactScreenSMS]);

  useEffect(() => {
    if (contactScreenSMS) {
      let lastAddedID = selectedContacts[0];
      let contactObj = ticketContacts.find((item) => {
        return Number(item.contactId) === Number(lastAddedID);
      });

      if (!contactObj?.phone) {
        alertInfo(
          "SMS will only be sent to Contacts with a Phone Number saved"
        );
      }
    }
  }, [selectedContacts]);

  useEffect(() => {
    let name = data.estimate.acceptedName;
    let options = [];

    if (name) {
      //check if it is one of the contacts
      let present = ticketContacts.filter((item) => {
        if (name === `${item.firstName} ${item.lastName}`) {
          return true;
        }
      });

      present = present[0];

      if (!present) {
        options.push({ label: data.estimate.acceptedName, value: "" });
      } else {
        set_client_data((current) => {
          return {
            ...current,
            acceptedContactId: present.contactId,
          };
        });
        setAcceptedByOptions({
          options: options,
          defaultValue: present.contactId,
        });
      }
    }
    ticketContacts.map((item) => {
      options.push({
        value: item.contactId,
        label: `${item.firstName} ${item.lastName}`,
      });
    });

    setAcceptedByOptions((current) => {
      return {
        ...current,
        options: options,
      };
    });
  }, [ticketContacts, data.estimate]);

  useEffect(() => {
    setDisplayMultiSelect(displayMultiSelect);
  }, [displayMultiSelect]);

  const [page, setPage] = useState(0);
  const [size, setSize] = useState(10);

  useEffect(() => {
    set_client_data({
      name: data.estimate.acceptedName,
      date: data.estimate.acceptedDate
        ? moment(data.estimate.acceptedDate, "YYYY-MM-DD").format("YYYY-MM-DD")
        : moment().format("YYYY-MM-DD"),
      channel: data.estimate.acceptedChannel,
    });

    if (estimateChangeDetect === false && kanbanScreenReset) {
      if (checkData(data.estimate.lines))
        alertError("Please fill in the Amounts first");
      setDisplayMultiSelect(!checkData(data.estimate.lines));
    }
  }, [data.estimate]);

  useEffect(() => {
    initEstimate();
  }, [ticketData?.estimateId]);

  useEffect(() => {
    if (ticketData?.estimateId) {
      initEstimate();
    }
    fetchTicketContacts(ticketData?.ticketId, setTicketContacts);
  }, []);

  const onChange = (page, pagesize) => {
    qbGetCustomers(searchText, page, pagesize)
      .then((res) => {
        setQBCustomers(res);
      })
      .catch(() => {
        alertError("Sorry! Error getting Customers from Quickbooks ");
      });
  };

  const onSearch = (e) => {
    qbGetCustomers(e?.target.value || "")
      .then((res) => {
        setQBCustomers(res);
      })
      .catch(() => {
        alertError("Sorry! Error getting Customers from Quickbooks ");
      });
  };

  const debouncedChangeHandler = useCallback(debounce(onSearch, 300), []);

  const onMarkAsCompletedChange = (update) => {
    let { name, value } = update;
    setMarkCompletedData((data) => deepUpdate(name, value, data));
  };

  const createEstimateFE = () => {
    let payloadStatus = checkPayloadValidity(data.estimate);
    if (payloadStatus) {
      var payload = convertToPayload(
        data.estimate,
        ticketData?.ticketId,

        total_amount_for_sections([
          "Accepted",
          "Change Order",
          "Sent",
          "Unsent",
        ])
      );
      payload.total = data.estimate.total;
      payload.subTotal = data.estimate.subTotal;
      createEstimate(payload)
        .then(() => {
          alertSuccess("Estimate saved successfully");
        })
        .catch((err) => {
          if (err.readableMessage) {
            alertError(err.readableMessage);
          } else {
            alertError("Sorry! We couldn't save your Estimate.");
          }
        });
    }
  };

  const createInvoiceCB = (createInvoicePromise, estimateID) => {
    createInvoicePromise
      .then((data) => {
        if (refreshCB) refreshCB();
        initEstimate();
        setNewQBCustomer(false);
        setQBCustomers();
        window.open(`${process.env.REACT_APP_ESTIMATE}${data.value}`, "_blank");
        setPartialInvoice(false);
        setSelectedLines([]);
        alertSuccess("Successfully created Invoice in Quickbooks!");
      })
      .catch((err) => {
        err.readableMessage
          ? alertError(err.readableMessage)
          : alertError("Sorry! Error Creating Invoice.");
      });
  };

  const total_amount = (lines) => {
    let amount = 0;
    lines.forEach((line) => {
      amount += Number(line.quantity) * Number(line.rate);
      if (line.materials) {
        line.materials.map((material) => {
          amount += Number(material.rate) * Number(material.quantity);
        });
      }
    });
    return amount;
  };

  //   const init = (estimateID) => {
  //     getEstimate(estimateID)
  //       .then((estimate) => {
  //         // setQbTransactionID(estimate.qbTransactionId);

  //         setData(convertToEstimateData(estimate, ticketData));

  //         let invoicedEstimateLines = [];

  //         estimate.invoices.map((invoice) => {
  //           invoice.lines?.map((line) => {
  //             invoicedEstimateLines.push({
  //               ...line,
  //               txnId: invoice.transactionId,
  //             });
  //           });
  //         });

  //         setInvoicedLines(invoicedEstimateLines);
  //       })
  //       .catch((error) => {
  //         alertError("Getting Estimate Failed");
  //       });
  //   };

  const createQBInvoice = (customerId = null) => {
    // response is null
    // create a new invoice

    setCustomerQbId(customerId ? customerId : ticketData?.qbCustomerId);

    alertInfo("Hold on while we create the invoice in QuickBooks");

    let estimateID = null;

    // if qbCustomerId is null
    // new flow
    if (ticketData?.estimateId && !data.unsaved) {
      estimateID = ticketData?.estimateId;

      createQbInvoiceFunc(customerId, estimateID);
    } else {
      let payloadStatus = checkPayloadValidity(data.estimate);
      if (payloadStatus) {
        var payload = convertToPayload(
          data.estimate,
          ticketData?.ticketId,
          total_amount_for_sections([
            "Accepted",
            "Change Order",
            "Sent",
            "Unsent",
          ])
        );

        payload.total = data.estimate.total;
        payload.subTotal = data.estimate.subTotal;

        createEstimate(payload)
          .then((res) => {
            estimateID = res.value;
            setQBEstimateId(estimateID);
            alertSuccess(
              "Successfully saved Estimate; Creating Invoice in QuickBooks"
            );
            createQbInvoiceFunc(customerId, estimateID);
          })
          .catch((err) => {
            if (err.readableMessage) {
              alertError(err.readableMessage);
            } else {
              alertError("Sorry! We couldn't save your Estimate.");
            }
          });
      }
    }
  };

  // Disable Invoice Creation when all lines in data are already in invoicedLines
  const allowInvoicing = () => {
    if (data.estimate.lines?.length === 0) {
      return false;
    }
    if (invoicedLines.length === 0) {
      return true;
    }
    const lines = data.estimate.lines.map((row) => row[COL_INDEX.ID]);
    const invoiced = invoicedLines.map((line) => line?.id);
    return lines.some((line) => !invoiced.includes(line));
  };

  const channelFormatter = (channel) => {
    switch (channel) {
      case "EMAIL":
        return "Email";
      case "CALL":
        return "Call";
      case "SELF":
        return "Self";
      default:
        return channel;
    }
  };

  return (
    <>
      <div className="md:ml-5 min-w-full md:p-10">
        <h1>
          <title>Help Squad | Estimate</title>
          <link rel="icon" href="/favicon.ico" />
        </h1>

        {warning?.overall && (
          <span className="absolute m-5 px-3 py-2 bg-red-500 font-semibold rounded shadow text-white text-sm">
            {warning.overall}
          </span>
        )}
        <div className={"bg-white rounded"}>
          <div className="rounded-sm">
            <div className="w-full flex flex-col md:flex-row md:items-start items-center justify-between">
              <span className="text-gray-700 mt-2 font-semibold text-3xl flex flex-col items-center gap-1 whitespace-pre">
                <div className="flex flex-col items-center md:items-start">
                  <div>
                    <span>Estimate</span>&nbsp;
                    <span className="relative top-px whitespace-pre">
                      #E{ticketData?.ticketId}
                    </span>
                  </div>
                  <span className="flex flex-col md:flex-row items-center md:items-baseline">
                    <>
                      <span
                        className={
                          `inline-flex items-center px-1.5 py-1 my-1 rounded-full text-xs font-medium ` +
                          (data.estimate.estimateStatus === "ACCEPTED" ||
                          data.estimate.estimateStatus === "PARTIALLY_ACCEPTED"
                            ? "bg-green-500 text-green-100"
                            : "bg-red-500 text-red-100")
                        }
                      >
                        {estimateStatusFormatted[data.estimate.estimateStatus]}
                      </span>

                      {data.estimate.estimateStatus !== "ACCEPTED" && (
                        <span
                          className="inline-flex items-center px-2.5 py-1 m-1 rounded-full text-xs font-medium bg-gray-400 text-white cursor-pointer mark-as-accepted-badge"
                          onClick={() => {
                            let status = checkData(data.estimate.lines);
                            if (status) {
                              alertError("Please fill in the Amounts first");
                            } else {
                              alertInfo("Please Mark the Items to be Accepted");
                              setDisplayMultiSelect(true);
                              document
                                .getElementById("estimate-table")
                                .scrollIntoView({ behavior: "smooth" });
                            }
                          }}
                        >
                          Click Here To Mark As Accepted
                        </span>
                      )}
                    </>
                  </span>
                </div>
              </span>
              <img
                src={data?.estimate?.vendor?.logoUrl}
                alt="Logo"
                className="w-40 h-40 md:w-48 md:h-48"
              />
              <div className="flex flex-col md:mt-5 md:ml-16 text-gray-700 text-xs text-center md:text-right">
                {[
                  `${data?.estimate?.vendor?.address.streetAddress1},
                    ${
                      data?.estimate?.vendor?.address.streetAddress2
                        ? data?.estimate?.vendor?.address.streetAddress2
                        : ""
                    }
                  `,
                  `${data?.estimate?.vendor?.address.city},
                    ${data?.estimate?.vendor?.address.state},
                    ${data?.estimate?.vendor?.address.zip}
                  `,
                  `${renderPhone(
                    data?.estimate?.vendor?.primaryContactNumber
                  )}`,
                  `${data?.estimate?.vendor?.supportEmail}`,
                ].map((text, text_index) => (
                  <span key={text_index}>{text}</span>
                ))}
              </div>
            </div>

            <div className="flex flex-col md:flex-row w-full justify-between gap-3">
              <div className="flex flex-col gap-4 items-center">
                <div className="md:ml-5 flex flex-col text-gray-700">
                  <span className="mt-3 font-semibold text-gray-900 py-1  items-center text-center md:text-left">
                    Bill To:
                  </span>
                  <div
                    className={
                      "flex flex-col gap-px px-3 py-1 bg-gray-50 rounded mt-1 text-sm  text-center md:text-left"
                    }
                  >
                    {ticketData.createdUnderContactId !== null && (
                      <>
                        <span>
                          {`${ticketData.primaryContact?.firstName || ""} ${
                            ticketData.primaryContact?.lastName || ""
                          }`}
                        </span>
                      </>
                    )}

                    <span>{`${ticketData.hoaName || ""}`}</span>
                    <span>{ticketData.streetAddress1}</span>
                    {(ticketData.unitNumber ?? ticketData.streetAddress2) && (
                      <span>
                        {ticketData.unitNumber ?? ticketData.streetAddress2}
                      </span>
                    )}
                    <span>
                      {ticketData.city}, {ticketData.state} {ticketData.zip}
                    </span>
                    {ticketData.createdUnderClientId !== null && (
                      <div className="flex flex-col mt-4">
                        <div className="flex flex-col md:flex-row items-center">
                          <span className="text-sm font-semibold">
                            Attention To:
                          </span>
                          <span className="px-2">
                            {`${ticketData.primaryContact?.firstName || ""} ${
                              ticketData.primaryContact?.lastName || ""
                            }`}
                          </span>
                        </div>
                        <span>{ticketData.clientName}</span>
                        <span>
                          <RenderAddressComponent
                            address={ticketData.billingAddress}
                          />
                        </span>
                      </div>
                    )}
                  </div>
                </div>
              </div>

              <div className="mt-8 sm:mt-16 mr-5 flex flex-col gap-4  text-center md:text-right">
                <div className="flex flex-col justify-center md:justify-end">
                  <span className="font-semibold">Estimate Date</span>
                  <span
                    className={
                      "text-gray-700 px-3 py-2 bg-gray-50 rounded mt-1 text-sm"
                    }
                  >
                    <div
                      className={
                        displayMultiSelect ? "pointer-events-none mr-5" : ""
                      }
                    >
                      <CustomFormatDatePicker
                        dateInit={
                          data?.estimate?.estimateDate
                            ? moment(
                                data?.estimate?.estimateDate,
                                "YYYY-MM-DD"
                              ).format("DD/MM/YYYY")
                            : ""
                        }
                        readOnly={isExternal}
                        onDateChange={(date) => {
                          setEstimateChangeDetect(true);
                          setData({
                            ...data,
                            estimate: {
                              ...data.estimate,
                              estimateDate: date,
                              expirationDate: moment(date)
                                .add(1, "M")
                                .format("YYYY-MM-DD"),
                            },
                          });
                        }}
                      />
                    </div>
                  </span>
                </div>
                <div className="flex flex-col justify-end">
                  <span className="font-semibold">Expiration Date</span>
                  <span
                    className={
                      "text-gray-700 px-3 py-2 bg-gray-50 rounded mt-1 text-sm"
                    }
                  >
                    <CustomFormatDatePicker
                      dateInit={
                        data?.estimate?.expirationDate
                          ? moment(
                              data?.estimate?.expirationDate,
                              "YYYY-MM-DD"
                            ).format("DD/MM/YYYY")
                          : ""
                      }
                      readOnly={isExternal}
                      onDateChange={(date) => {
                        setEstimateChangeDetect(true);

                        setData({
                          ...data,
                          estimate: {
                            ...data.estimate,
                            expirationDate: date,
                          },
                        });
                      }}
                    />
                  </span>
                </div>

                <div className="sm:mt-5 text-gray-700 flex flex-col">
                  <span className="font-semibold text-lg">Amount</span>
                  <span className="font-bold text-2xl text-green-600">
                    $ {estimate_display_total()}
                  </span>
                </div>
              </div>
            </div>

            <div
              id="estimate-table"
              className="flex flex-col md:flex-row items-center w-full my-3"
            >
              <EstimateTableNew
                estimateItems={estimateItems}
                isExternal={isExternal}
                multiSelect={
                  partialInvoice
                    ? "PARTIAL_INVOICE"
                    : displayMultiSelect
                    ? "ACCEPT_ESTIMATE"
                    : undefined
                }
                setSelected={setSelectedLines}
                openInvoiceModal={(invoiceId) => {
                  setSelectedInvoice({
                    invoiceId: invoiceId,
                    ticketId: ticketData.ticketId,
                    estimateId: ticketData.estimateId,
                  });
                  setShowInvoice(true);
                }}
                qbInvoicedLines={invoicedLines}
                refreshCB={initEstimate}
              />
            </div>

            <div
              className={
                "flex flex-col md:flex-row gap-4 sm:gap-10 w-full justify-between mt-7"
              }
            >
              <p className="flex flex-col text-xs text-gray-600 mt-10 h-32 overflow-y-auto gap-2 whitespace-pre-wrap">
                {data?.estimate?.vendor?.estimateTermsAndConditions}
              </p>
              <div className="w-full flex flex-col my-5 gap-4 items-end text-gray-600">
                <div className="flex flex-row gap-8 font-semibold text-xl">
                  Total
                  <span>${estimate_display_total()}</span>
                </div>
              </div>
            </div>
          </div>

          <div className="mt-5 flex flex-col md:flex-row-reverse items-center w-full p-2">
            <div className="flex flex-col gap-3 md:items-baseline items-center mx-2 sm:mr-10">
              <div className="flex flex-col-reverse  md:flex-row-reverse items-center gap-7 w-full">
                {displayMultiSelect &&
                partialInvoice === false &&
                !checkData(data.estimate.lines) ? (
                  <MaterialForm
                    className={"w-64"}
                    data={{
                      acceptedContactId: client_data.acceptedContactId,
                    }}
                    renderArray={[
                      {
                        name: "acceptedContactId",
                        label: "Accepted By",
                        inputType: "SELECT",
                        defaultValue: client_data.acceptedContactId,
                        options: acceptedByOptions.options,
                      },
                    ]}
                    onSubmit={(_) => {}}
                    onChange={(update) => {
                      const { name, value } = update;

                      set_client_data({
                        ...client_data,
                        acceptedContactId: value,
                      });
                    }}
                  />
                ) : (
                  <input
                    type={""}
                    readOnly={displayMultiSelect ? false : true}
                    value={
                      displayMultiSelect
                        ? client_data.name
                        : data?.estimate?.acceptedName
                    }
                    onChange={(e) => {
                      setWarning({ ...warning, name_not_filled: false });
                      set_client_data({
                        ...client_data,
                        name: e.target.value,
                      });
                    }}
                    placeholder={"Enter name"}
                    className={
                      "ml-3 px-2 py-1 rounded  focus:bg-gray-200 border-0 text-gray-600 focus:border-0 focus:ring-0 w-60 focus:outline-none " +
                      (displayMultiSelect ? "" : "bg-gray-100") +
                      (warning["name_not_filled"]
                        ? "border border-red-300"
                        : "")
                    }
                  />
                )}
                <span className="whitespace-pre text-gray-600 font-semibold text-sm">
                  Accepted By
                </span>
              </div>
              <div
                className={
                  "flex flex-col-reverse md:flex-row-reverse items-center gap-6 w-full" +
                  displayMultiSelect
                }
              >
                <div className={"w-64 " + (displayMultiSelect ? "" : "px-3")}>
                  <CustomFormatDatePicker
                    readOnly={
                      displayMultiSelect && partialInvoice === false
                        ? false
                        : true
                    }
                    dateInit={
                      client_data.date
                        ? moment(client_data.date, "YYYY-MM-DD").format(
                            "DD/MM/YYYY"
                          )
                        : null
                    }
                    noDefaultValue={
                      !displayMultiSelect && !data.estimate.acceptedDate
                    }
                    onDateChange={(date) => {
                      setWarning({ ...warning, date_not_filled: false });
                      set_client_data({
                        ...client_data,
                        date: date,
                      });
                    }}
                  />
                </div>

                <span className="whitespace-pre text-gray-600 font-semibold text-sm items-center">
                  Accepted On
                </span>
              </div>
              <div className="flex flex-col-reverse md:flex-row-reverse items-center gap-7 w-full">
                {displayMultiSelect &&
                partialInvoice === false &&
                !checkData(data.estimate.lines) ? (
                  <MaterialForm
                    data={{ channel: client_data.channel || "EMAIL" }}
                    className="w-64"
                    renderArray={[
                      {
                        name: "Accepted Via",
                        inputType: "SELECT",
                        defaultValue: client_data.channel
                          ? client_data.channel
                          : "EMAIL",
                        options: [
                          { label: "SMS", value: "SMS" },
                          { label: "EMAIL", value: "EMAIL" },
                          { label: "CALL", value: "CALL" },
                        ],
                      },
                    ]}
                    onSubmit={(_) => {}}
                    onChange={(update) => {
                      const { name, value } = update;

                      set_client_data({
                        ...client_data,
                        channel: value,
                      });
                    }}
                  />
                ) : (
                  <>
                    {isExternal ? null : (
                      <input
                        type={""}
                        readOnly={displayMultiSelect ? false : true}
                        value={channelFormatter(client_data?.channel)}
                        onChange={(e) => {
                          setWarning({ ...warning, "": false });
                          set_client_data({
                            ...client_data,
                            channel: e.target.value,
                          });
                        }}
                        placeholder={"Channel"}
                        className={
                          "ml-3 px-2 py-1 rounded  focus:bg-gray-200 border-0 text-gray-600 focus:border-0 focus:ring-0 w-60 focus:outline-none " +
                          (displayMultiSelect ? "" : "bg-gray-100") +
                          (warning[""] ? "border border-red-300" : "")
                        }
                      />
                    )}
                  </>
                )}
                {!isExternal && (
                  <span className="whitespace-pre text-gray-600 font-semibold text-sm">
                    Channel
                  </span>
                )}
              </div>
            </div>
          </div>
          {!(
            displayMultiSelect &&
            partialInvoice === false &&
            !checkData(data.estimate.lines)
          ) ? (
            <div className="flex flex-col md:flex-row gap-2 mt-5 items-center md:float-right">
              <div className="">
                <Button
                  onClick={(_) => {
                    let payloadStatus = checkPayloadValidity(data.estimate);
                    if (payloadStatus) {
                      var payload = convertToPayload(
                        data.estimate,
                        ticketData?.ticketId,
                        total_amount_for_sections([
                          "Accepted",
                          "Change Order",
                          "Sent",
                          "Unsent",
                        ])
                      );
                      payload.total = data.estimate.total;
                      payload.subTotal = data.estimate.subTotal;
                      updateEstimate(ticketData?.estimateId, [
                        {
                          op: "replace",
                          path: "/expirationDate",
                          value: payload.expirationDate,
                        },
                      ]);
                      createEstimate(payload)
                        .then(() => {
                          setEstimateChangeDetect(false);
                          if (kanbanScreen) {
                            setKanbanScreenReset(false);
                          }
                          // setShowModal(false);
                          initEstimate();

                          alertSuccess("Estimate saved successfully");
                        })
                        .catch((err) => {
                          if (err.readableMessage) {
                            alertError(err.readableMessage);
                          } else {
                            alertError(
                              "Sorry! We couldn't save your Estimate."
                            );
                          }
                        });
                    }
                  }}
                  style={{ color: "#38A169" }}
                  className="float-right"
                >
                  Save
                </Button>
              </div>
              <>
                <div className="">
                  <Tooltip
                    title={
                      estimateChangeDetect
                        ? "Please save the modified Estimate before Sending it"
                        : ""
                    }
                    placement="top-start"
                  >
                    <div>
                      <Button
                        onClick={(_) => {
                          downloadPDF(ticketData?.estimateId);
                        }}
                        style={{
                          color: estimateChangeDetect ? "#c6f6d5" : "#38A169",
                        }}
                        className={
                          "float-right save-and-send-pdf-estimate" +
                          (estimateChangeDetect ? " disabled" : "")
                        }
                      >
                        Download PDF
                        <DocumentIcon className="h-3 w-3" />
                      </Button>
                      <Button
                        onClick={(_) => {
                          let payloadStatus = checkPayloadValidity(
                            data.estimate
                          );
                          if (payloadStatus) {
                            setShowContactModal(true);
                          }
                        }}
                        style={{
                          color: estimateChangeDetect ? "#c6f6d5" : "#38A169",
                        }}
                        className={
                          "float-right save-and-send-pdf-estimate" +
                          (estimateChangeDetect ? " disabled" : "")
                        }
                      >
                        Send PDF&nbsp;
                        <DocumentIcon className="h-3 w-3" />
                      </Button>
                    </div>
                  </Tooltip>
                </div>
                <div className="flex items-center">
                  {allowInvoicing() &&
                  activeUserStore.isSuperAdvisor() &&
                  data.estimate.estimateStatus !== "UNSENT" &&
                  data.estimate.estimateStatus !== "SENT" &&
                  data.estimate.estimateStatus !== "REJECTED" ? (
                    <div className="flex items-center">
                      {partialInvoice && (
                        <Button
                          onClick={(_) => {
                            setPartialInvoice(false);
                            setDisplayMultiSelect(false);
                          }}
                          style={{ color: "#38A169" }}
                          className="float-right uppercase"
                        >
                          Cancel
                        </Button>
                      )}
                      {!isExternal && (
                        <Button
                          onClick={(_) => {
                            if (vendorName === "Help Squad") {
                              setInvoiceCreation(true);
                            } else {
                              createSquadHubInvoiceFunc();
                            }
                          }}
                          style={{ color: "#38A169" }}
                          className="float-right"
                        >
                          Create Invoice
                        </Button>
                      )}
                      {!partialInvoice && (
                        <PartialInvoiceMenu
                          onClickCB={(_) => {
                            setPartialInvoice(true);
                            setDisplayMultiSelect(true);
                          }}
                          onViewClick={() => setShowListInvoices(true)}
                        />
                      )}
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </>
            </div>
          ) : (
            <>
              <div className="flex flex-col md:flex-row gap-2 mt-5 float-right">
                <div className="">
                  <Button
                    onClick={(_) => {
                      let status = checkEstimateAcceptData(client_data);
                      if (status) {
                        if (selectedLines.length === 0) {
                          withConfirmation({
                            title: "Reject Estimate",
                            description: `Are you sure you want to reject the Estimate?
                          
                          If not, please click Cancel and select the Items to be accepted using the checkboxes.`,
                            onConfirm: () => {
                              acceptEstimateHandler();
                            },
                          });
                        } else acceptEstimateHandler();
                      } else {
                        alertError("Please fill in the Acceptance Details.");
                      }
                    }}
                    style={{ color: "#38A169" }}
                    className="float-right accept-estimate-button"
                  >
                    Accept Estimate
                  </Button>
                </div>
              </div>

              <div className="flex flex-col md:flex-row gap-2 mt-5 float-right">
                <div className="">
                  <Button
                    onClick={(_) => {
                      setDisplayMultiSelect(false);
                    }}
                    style={{ color: "red" }}
                    className="float-right"
                  >
                    Cancel
                  </Button>
                </div>
              </div>
            </>
          )}
        </div>
      </div>

      <MaterialModal
        open={qbCustomers}
        setOpen={(_) => setQBCustomers()}
        extended
      >
        <button
          type="button"
          className="bg-green-600 hover:bg-green-700 flex flex-col md:flex-row gap-4 px-3 py-1 text-white rounded border shadow-md float-right"
          onClick={() => setNewQBCustomer((current) => !current)}
        >
          Create new Customer in QuickBooks
        </button>
        <h3 className="text-xl font-bold">
          Select from Existing Customers in Quickbooks
        </h3>
        <br />
        <div className="flex flex-col md:flex-row items-center gap-2 cursor-pointer">
          <TextField
            id="search"
            className="flex-1"
            label="Search"
            value={searchText}
            onChange={(e) => {
              e.persist();
              setSearchText(e.target.value);
              debouncedChangeHandler(e);
            }}
            variant="outlined"
          />
          <button
            onClick={() => {
              setSearchText("");
              onSearch({ target: { value: "" } });
            }}
          >
            CLEAR
          </button>
        </div>

        <div className="py-6">
          <MaterialTable
            data={qbCustomers?.customers
              ?.map((contact) => {
                return {
                  id: contact.customerId,
                  data: [
                    contact.displayName,
                    contact.primaryEmailAddress,
                    contact.primaryPhone,
                    `${contact.billingAddress?.streetAddress1 ?? " - "}, ${
                      contact.billingAddress?.streetAddress2 ?? " - "
                    }`,
                  ],
                };
              })
              // Show only 10 rows
              .slice(0, 10)}
            head={[
              { id: "displayName", label: "Display Name" },
              { id: "primaryEmailAddress", label: "Email" },
              { id: "primaryPhone", label: "Phone" },
              { id: "billingAddress", label: "Address" },
            ]}
            onClickCB={(customerId) => {
              setQBCustomers();
              createQBInvoice(customerId);
            }}
            pageRows={size}
            totalData={qbCustomers?.totalCount}
            currentPage={page}
            paginationCB={(value) => {
              onChange(value + 1, size);
              setPage(value);
            }}
            rowChangeCB={(value) => {
              setSize(value);
            }}
          />
        </div>
      </MaterialModal>
      <MaterialModal
        open={showContactModal}
        setOpen={(_) => setShowContactModal(false)}
        extended
      >
        <>
          <div className="flex flex-col md:flex-row justify justify-end items-center">
            <div className="items-center">
              <div className="flex flex-row items-center">
                <div className="flex items-center">
                  <input
                    id="notify-client"
                    aria-describedby="notify-client-helper-text"
                    name="notify-client"
                    type="checkbox"
                    className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
                    onChange={(e) => {
                      setContactScreenSMS((current) => !current);
                    }}
                    checked={contactScreenSMS ? true : false}
                  />
                </div>
                <div className="ml-3 text-sm">
                  <label
                    htmlFor="notify-client"
                    className="font-medium text-gray-700"
                  >
                    Send SMS
                  </label>
                </div>
              </div>
            </div>
            <div>
              <Button
                onClick={(_) => {
                  alertSuccess("Sending Email...");
                  sendEstimatePdf({
                    contactIds: selectedContacts,
                    ticketId: ticketData?.ticketId,
                    channel: "EMAIL",
                    sendSMS: contactScreenSMS,
                  })
                    .then(() => {
                      onSuccessCB && onSuccessCB(false);
                      setShowModal(false);
                      setTicketContacts(null);
                      alertSuccess("Estimate sent successfully");
                    })
                    .catch((err) => {
                      if (err.readableMessage) {
                        alertError(err.readableMessage);
                      } else {
                        alertError("Sorry! Error Sending Estimate PDF.");
                      }
                    });
                }}
                style={{ color: "#38A169" }}
                className="float-right p-4 send-estimate-button"
              >
                Send Estimate
              </Button>
            </div>
          </div>
          <br />
          <br />
          <br />

          <MaterialTable
            data={ticketContacts?.map((contact) => {
              return {
                id: contact.contactId,
                data: [
                  contact.firstName,
                  contact.lastName,
                  contact.email,
                  renderPhone(contact.phone),
                ],
              };
            })}
            head={[
              { id: "firstName", label: "First Name" },
              { id: "lastName", label: "Last Name" },
              { id: "email", label: "Email" },
              { id: "phoneNumber", label: "Phone Number" },
            ]}
            onClickCB={(contactId) => {}}
            defaultOrderBy={"firstName"}
            selected={selectedContacts}
            setSelected={setSelectedContacts}
            multiSelect={true}
          />
        </>
      </MaterialModal>
      <MaterialModal
        open={showInvoice}
        setOpen={(_) => {
          setShowInvoice((current) => !current);
        }}
        label="estimate-modal"
        describedBy="create-view-estimate"
        width={80}
        className="px-1 py-1"
      >
        <InvoiceForm invoiceId={selectedInvoice.invoiceId} />
      </MaterialModal>

      <MaterialModal
        open={listInvoices}
        setOpen={(_) => {
          setShowListInvoices((current) => !current);
        }}
      >
        <ListInvoices ticketId={data.estimate.ticketId} />
      </MaterialModal>
      <MaterialModal
        open={invoiceCreation}
        setOpen={(_) => setInvoiceCreation(false)}
        extended
      >
        <div className="ml-3">
          <h3 className="text-sm font-medium ">
            Where would you like to create the invoice?
          </h3>
          <div className="mt-8">
            <div className="-mx-2 -my-1.5 flex flow-root">
              <button
                type="button"
                className="bg-green-600 hover:bg-green-700 flex flex-col md:flex-row gap-4 px-3 py-1 text-white rounded border shadow-md float-right"
                onClick={(_) => {
                  if (partialInvoice && selectedLines?.length === 0) {
                    alertInfo("Please select at least one line item");
                    return;
                  }

                  setInvoiceCreation(false);

                  createEstimateFE();
                  connectToQuickBooks()
                    .then((res) => {
                      if (res.value) {
                        setOpenQuickModal({
                          url: res.value,
                          open: true,
                        });
                      } else {
                        if (ticketData?.qbCustomerId) {
                          createQBInvoice();
                        } else {
                          qbGetCustomers()
                            .then((res) => {
                              setQBCustomers(res);
                            })
                            .catch(() => {
                              alertError(
                                "Sorry! Error getting Customers from Quickbooks "
                              );
                            });
                        }
                      }
                    })
                    .catch(() => {
                      alertError("Sorry! Error Connecting to QuickBooks.");
                    });
                }}
              >
                QuickBooks
              </button>
              <button
                type="button"
                className="mx-3 px-2 py-1.5 rounded-md text-sm font-medium float-right rounded border border-newBlue-400"
                onClick={() => {
                  createSquadHubInvoiceFunc();
                }}
              >
                Squad Hub
              </button>
            </div>
          </div>
        </div>
      </MaterialModal>
      <MaterialModal
        open={newQBCustomer}
        setOpen={(_) => setNewQBCustomer(false)}
        extended
      >
        <div className="ml-3">
          <h3 className="text-sm font-medium ">
            Are you sure you want to create a new Customer in QuickBooks?
          </h3>
          <p className="text-xs">
            Please make sure your billing address is up to date
          </p>

          <div className="mt-4">
            <div className="-mx-2 -my-1.5 flex flow-root">
              <button
                type="button"
                className="bg-green-600 hover:bg-green-700 flex flex-col md:flex-row gap-4 px-3 py-1 text-white rounded border shadow-md float-right"
                onClick={() => {
                  createQBInvoice();
                }}
              >
                Yes
              </button>
              <button
                type="button"
                className="ml-3 px-2 py-1.5 rounded-md text-sm font-medium float-right"
                onClick={() => setNewQBCustomer(false)}
              >
                No
              </button>
            </div>
          </div>
        </div>
      </MaterialModal>
      <MaterialModal
        open={openQuickModal.open}
        setOpen={(_) => setOpenQuickModal({ open: false })}
        extended
      >
        <div className="ml-3">
          <h3 className="text-sm font-medium ">
            QuickBooks account has not been connected yet. Would you like to
            connect now?
          </h3>

          <div className="mt-4">
            <div className="-mx-2 -my-1.5 flex flow-root">
              <button
                type="button"
                className="bg-green-600 hover:bg-green-700 flex flex-col md:flex-row gap-4 px-3 py-1 text-white rounded border shadow-md float-right"
                onClick={() => {
                  window.open(openQuickModal.url, "_blank");
                  setOpenQuickModal({ open: false });
                }}
              >
                Yes
              </button>
              <button
                type="button"
                className="ml-3 px-2 py-1.5 rounded-md text-sm font-medium float-right"
                onClick={() => {
                  setOpenQuickModal({ open: false });
                }}
              >
                No
              </button>
            </div>
          </div>
        </div>
      </MaterialModal>

      {markCompletedModal && (
        <MarkAsAcceptedModal
          visibleVar={markCompletedModal}
          clearCB={() => {
            setMarkCompletedModal(false);
          }}
          data={markCompletedData}
          ticketContacts={ticketContacts}
          onChangeCB={onMarkAsCompletedChange}
          acceptCB={(_) => {
            estimateAccept(ticketData?.estimateId, markCompletedData).then(
              (data) => {
                setMarkCompletedModal(false);
                initEstimate();
                alertSuccess("Estimate Accepted Successfully");
              }
            );
          }}
        />
      )}

      {selectBillingEmailForm[0].options.length > 0 && (
        <MaterialModal
          open={selectBillingEmailForm[0].options.length > 0 ? true : false}
          setOpen={(_) => {
            setSelectBillingEmailForm((form) =>
              form.map((input) =>
                input.name === "billingEmail"
                  ? {
                      ...input,
                      options: [],
                    }
                  : input
              )
            );
          }}
          label="new-user-modal"
          describedby="create-new-user"
        >
          <div className="p-4 max-w-sm w-full">
            <div className="flex gap-2">
              {/* <BusinessIcon /> */}
              <Typography variant="h5">
                Please select a Billing Email for the Invoice
              </Typography>
            </div>
            <MaterialForm
              data={selectedBillingEmail}
              renderArray={selectBillingEmailForm}
              onSubmit={(_) => {}}
              onChange={(update) => {
                const { name, value } = update;
                setSelectedBillingEmail((data) =>
                  deepUpdate(name, value, selectedBillingEmail)
                );
              }}
            />

            <div className="float-right">
              <Button
                color="primary"
                className="float-right p-4"
                onClick={(_) => {
                  let billingEmail =
                    selectedBillingEmail.billingEmail.split(":")[1];

                  setSelectBillingEmailForm((form) =>
                    form.map((input) =>
                      input.name === "billingEmail"
                        ? {
                            ...input,
                            options: [],
                          }
                        : input
                    )
                  );

                  const createInvoicePromise = createInvoice({
                    qbCustomerId: customerQBId,
                    estimateId: QBEstimateId,
                    billingEmail: billingEmail,
                    lines: partialInvoice ? selectedLines : data.estimate.lines,
                  });
                  createInvoiceCB(createInvoicePromise, QBEstimateId);

                  alertInfo("Please Wait Until We Create The Invoice");
                }}
              >
                Save
              </Button>
            </div>
          </div>
        </MaterialModal>
      )}
    </>
  );

  function estimate_display_total() {
    if (data.estimate.estimateStatus === "SENT")
      return total_amount_for_sections(["Accepted", "Change Order", "Sent"]);
    else return total_amount_for_sections(["Accepted"]);
  }

  //Create function that returns total of the lines of array of section names passed in
  function total_amount_for_sections(sections) {
    console.log("LOG: total_amount_for_sections -> sections", sections);
    let lines = [];
    Object.entries(estimateItems).map(([sectionName, section]) => {
      if (sections.includes(sectionName)) {
        lines = lines.concat(section.lines);
      }
    });
    return total_amount(lines).toFixed(2);
  }

  function createSquadHubInvoiceFunc() {
    let srIds = [];

    getEstimate(ticketData?.estimateId)
      .then((estimate) => {
        const filteredSrs = estimate.lines.filter(
          (item) => item.invoiceId === null
        );

        if (partialInvoice) {
          selectedLines.forEach((item) => {
            // srIds.push(item[8]);
            srIds.push(item.id);
          });
        } else {
          filteredSrs.forEach((item) => {
            srIds.push(item.id);
          });
        }
        if (filteredSrs.length === 0) {
          alertInfo("All Accepted Service Requests are Invoiced");
          setInvoiceCreation(false);
        } else {
          createInvoice({
            type: "INTERNAL",
            srIds: srIds,
            estimateId: ticketData?.estimateId,
          })
            .then((item) => {
              setSelectedInvoice({
                estimateId: ticketData?.estimateId,
                ticketId: data.estimate.ticketId,
                invoiceId: item.value,
              });
              setInvoiceCreation(false);
              setPartialInvoice(false);
              setSelectedLines([]);
              setDisplayMultiSelect(false);
              setShowInvoice(true);
              initEstimate();
            })
            .catch((_) => {
              alertError("Error while creating invoice");
            });
        }
      })
      .catch((_) => {
        alertError("Error while creating invoice");
      });
  }

  function createQbInvoiceFunc(customerId, estimateID) {
    getBillingEmails(ticketData.ticketId).then((billingEmail) => {
      if (Object.keys(billingEmail).length > 0) {
        let parsed = [];

        let keys = Object.keys(billingEmail);
        keys.forEach((item, index) => {
          parsed.push({
            label: `${item}: ${billingEmail[item]}`,
            value: billingEmail[item],
          });
        });
        setSelectedBillingEmail(
          billingEmail["Client Primary Contact Email"] || ""
        );

        setSelectBillingEmailForm((form) =>
          form.map((input) =>
            input.name === "billingEmail"
              ? {
                  ...input,
                  options: [...parsed],
                  defaultValue: billingEmail["Client Primary Contact Email"]
                    ? "Client Primary Contact Email" +
                      ":" +
                      billingEmail["Client Primary Contact Email"]
                    : "",
                }
              : input
          )
        );
      } else {
        const createInvoicePromise = createInvoice({
          qbCustomerId: customerId,
          estimateId: estimateID,
          lines: partialInvoice ? selectedLines : data.estimate.lines,
        });
        createInvoiceCB(createInvoicePromise, estimateID);
      }
    });
  }

  function initEstimate() {
    let initialEstimateItems = {
      Accepted: { lines: [], total: 0 },
      Rejected: { lines: [], total: 0 },
      "Change Order": { lines: [], total: 0 },
      Unsent: { lines: [], total: 0 },
      Sent: { lines: [], total: 0 },
    };

    getEstimate(ticketData?.estimateId)
      .then((estimate) => {
        setData({
          ...data,
          estimate: estimate,
          customer: ticketData.clientName,
        });

        estimate.lines.forEach((line) => {
          if (line.isDraft === false)
            initialEstimateItems["Accepted"].lines.push(line);
          else if (
            line.isEstimateSent === true &&
            line.isDraft === true &&
            line.isChangeOrder === false &&
            estimate.estimateStatus !== "SENT"
          )
            initialEstimateItems["Rejected"].lines.push(line);
          else if (
            line.isEstimateSent === true &&
            line.isDraft === true &&
            line.isChangeOrder === true
          )
            initialEstimateItems["Change Order"].lines.push(line);
          else if (
            line.isEstimateSent === false &&
            line.isDraft === true &&
            estimate.estimateStatus !== "SENT"
          )
            initialEstimateItems["Unsent"].lines.push(line);
          else if (estimate.estimateStatus === "SENT")
            initialEstimateItems["Sent"].lines.push(line);
          else console.log("Error in line status");
        });

        //Calculate total for each section
        Object.entries(initialEstimateItems).forEach(
          ([sectionName, section]) => {
            initialEstimateItems[sectionName].total = total_amount(
              section.lines
            );
          }
        );
        setEstimateItems(initialEstimateItems);
        let invoicedEstimateLines = [];

        estimate.invoices.map((invoice) => {
          invoice.lines?.map((line) => {
            invoicedEstimateLines.push({
              ...line,
              txnId: invoice.transactionId,
            });
          });
        });

        setInvoicedLines(invoicedEstimateLines);
      })
      .catch((error) => {
        alertError("Getting Estimate Failed");
      });
  }

  function acceptEstimateHandler() {
    console.log("LOG: acceptEstimateHandler", acceptEstimateCB, selectedLines);
    if (acceptEstimateCB) {
      acceptEstimateCB(ticketData?.estimateId, selectedLines, client_data);
      initEstimate();
    } else {
      let lines = selectedLines;
      let ids = [];
      lines.map((item) => {
        ids.push(item.id);
      });
      console.log("LOG: acceptEstimateHandler -> ids", ids);
      estimateAccept(ticketData?.estimateId, {
        acceptIds: ids,
        acceptDate: client_data.date,
        acceptedContactId: client_data.acceptedContactId,
        acceptedChannel: client_data.channel,
      })
        .then((data) => {
          alertSuccess(
            "Estimate " + (ids.length > 0 ? "Accepted" : "Rejected")
          );
          initEstimate();
          initEstimate();
          setDisplayMultiSelect(false);
        })
        .catch((e) => {
          if (e.readableMessage) {
            alertError(e.readableMessage);
          } else {
            alertError("Estimate Not Accepted");
          }
        });
    }
  }
}

const PartialInvoiceMenu = ({ onClickCB, onViewClick }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <ArrowDropDownIcon
        className="cursor-pointer hover:bg-gray-100"
        onClick={handleClick}
      />
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        PaperProps={{}}
      >
        <MenuItem onClick={onClickCB}>Partial Invoice</MenuItem>
        <MenuItem onClick={onViewClick}>View Invoices</MenuItem>
      </Menu>
    </div>
  );
};
