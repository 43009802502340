//boiler plate for react component
import React, { useEffect, useState } from "react";



import { Avatar, Button, Typography } from "@mui/material";
import { navigate } from "raviger";
import { useRecoilState } from "recoil";



import { alertError, alertSuccess } from "../../actions/AlertActions";
import { useAppActions } from "../../actions/AppActions";
import { assignOwner, // createSR,
createTicket, getBuilding, getBuildingClients, getBuildingManagersNewApi, getBuildingsUnderCommercialClient, getBuildingsUnderIndividualClient, getOwners, getPipelines, getTicket, getTicketSRs, markTodo, updateTicket } from "../../api/Api";
import { getCurrentUser } from "../../api/Api";
import useTicketForm from "../../hooks/useTicketForm";
import { getClientOptions, patchTicket } from "../../utils/ModelUtils";
import { conditionalArrayEntry } from "../../utils/ObjectUtills";
import { CallbackLink } from "../../utils/RouterUtils";
import { deepUpdate } from "../../utils/StateUtils";
import { properStrings, renderAddress } from "../../utils/StringUtils";
import { AddIcon, AddIconBlue, ChatBubbleIcon, InvoiceIcon, LoadingIcon, NotepadIcon, PenIcon } from "../common/AppIcons";
// import ServiceRequestDetail from "../service/ServiceRequestDetail";
import Carousel from "../common/Carousel";
import MaterialModal from "../common/MaterialModal";
import NewServiceRequestPopup from "../common/NewServiceRequestPopup";
import PreviewLink from "../common/PreviewLink";
import { make as MaterialForm } from "../common/form/MaterialForm.bs";
import ExternalMessaging from "../crm/ExternalMessaging";
import CreateSR from "../service/CreateSR";
import SelectOwner from "../settings/SelectOwner";
import TicketOwner from "../tickets/TicketOwner";
import AssignTicket from "./AssignTicket";
import EstimateForm from "./EstimateForm";
import { estimateStatusFormatted } from "./NewKanbanBoard";
import ServiceRequestTile from "./ServiceRequestTile";
import DetailsSection from "./components/DetailsSection";
import ListInvoices from "./components/ListInvoices";
import googleImage from "./google_map_link.png";



interface Contact {
  contactId: number;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  unitNumber?: string;
  position?: string;
}

interface LightBoxImages {
  images: string[];
  photoIndex: number;
}

export default function NewCreateTicket({
  baseData,
  showEstimate,
  urlTicketId,
  territory,
  onSubmitCB,
  onSuccessCB,
  refreshCB,
}: {
  baseData?: any;
  showEstimate?: boolean;
  urlTicketId?: string;
  territory?: string;
  onSubmitCB?: (data: any) => void;
  onSuccessCB?: (data: any) => void;
  refreshCB?: (data: any) => void;
}) {
  const [data, setData] = useState(baseData ?? {});
  const [pipelines, setPipelines] = useState([]);
  const [serviceRequests, setServiceRequests] = useState();
  const [showModal, setShowModal] = useState(false);
  const [showInvoice, setShowInvoice] = useState(false);
  const [showAssignTicket, setShowAssignTicket] = useState(false);

  const [owners, setOwners] = useState([]);

  const [estimateShow, setEstimateShow] = useState(showEstimate);

  const [srForUpdate, setSrForUpdate] = useState();
  const [ticketEdit, setTicketEdit] = useState(false);
  const [lightboxImages, setLightboxImages] = useState<
    undefined | LightBoxImages
  >();
  const [action, setAction] = useState();

  const [changeBuilding, toggleChangeBuilding] = useState(false);
  const [changeContacts, toggleChangeContacts] = useState(false);

  const [clientBuildings, setClientBuildings] = useState();
  const [contacts, setContacts] = useState<Contact[]>([]);
  const [buildingData, setBuildingData] = useState([]);

  const [changeOwner, setChangeOwner] = useState({
    ticket: null,
    ownerId: null,
    display: false,
  });

  const [currentUser, setCurrentUser] = useState();

  const { withConfirmation, quickMessage } = useAppActions();

  useEffect(() => {
    getCurrentUser().then((data) => {
      setCurrentUser(data);
    });
  }, []);

  const ticketForm = useTicketForm(data, setData);

  const srSelectCB = (sr) => {
    setSrForUpdate(sr);
  };

  const onChangeUpdate = (update) => {
    const { name, value } = update;
    setSrForUpdate((data) => deepUpdate(name, value, data));
  };

  const onChange = (update) => {
    const { name, value } = update;
    setData((data) => deepUpdate(name, value, data));
  };

  const fetchTicketDetail = (ticketId, setData) =>
    getTicket(ticketId)
      .then((data) => {
        setData(data);
        if (estimateShow) {
          if (parseInt(urlTicketId) === ticketId) {
            setShowModal(true);
            setEstimateShow(false);
          }
        }
      })
      .catch((_) => alertError("Couldn't fetch Ticket Details"));

  const onSubmit = (event) => {
    event.preventDefault();

    if (onSubmitCB) {
      console.log("Callback");
      return onSubmitCB(event);
    } else {
      let payload = {
        ...data,
        stageName: "",
      };
      const ticketApi = data.ticketId ? updateTicket : createTicket;
      ticketApi(payload)
        .then((_response) => {
          data.ticketId
            ? alertSuccess("Successfully Updated Ticket")
            : alertSuccess("Successfully Created Ticket");
          onSuccessCB && onSuccessCB();
        })
        .catch((err) => {
          if (
            err.readableMessage ===
            "Please Mark the Estimate as Accepted first."
          ) {
            alertError(err.readableMessage);
          } else if (err.readableMessage) {
            alertError(err.readableMessage);
          } else {
            data.ticketId
              ? alertError("Error Updating Ticket")
              : alertError("Error Creating Ticket");
          }
        });
    }
  };

  const fetchServiceRequests = (ticketId, setServiceRequests) =>
    getTicketSRs(ticketId)
      .then(setServiceRequests)
      .catch((_) => alertError("Couldn't fetch SR Details"));

  useEffect(() => {
    if (data?.buildingId) {
      getBuilding(data.buildingId)
        .then((building) => {
          setBuildingData(building);
          setContacts((data) => [...data, ...building.contacts]);
        })
        .catch((err) => {
          alertError("Couldn't Fetch Select Building");
        });
      getBuildingManagersNewApi(data.buildingId)
        .then((managerData) => {
          setContacts((data) => {
            return [...data, ...managerData];
          });
        })
        .catch((err) => {
          console.log(err);
        });
    } else setContacts([]);
  }, [data?.buildingId]);

  useEffect(() => {
    getPipelines().then((data) => {
      setPipelines(data);
    });

    getOwners(territory).then((data) => {
      const options = data.map(
        (item: { id: any; firstName: any; lastName: any }) => {
          return {
            value: item.id,
            label: `${item.firstName} ${item.lastName}`,
          };
        }
      );
      setOwners(options);
    });
  }, []);

  useEffect(() => {
    getTicket(baseData.ticketId)
      .then((data) => {
        if (estimateShow) {
          if (parseInt(urlTicketId ?? "") === data.ticketId) {
            setShowModal(true);
            setEstimateShow(false);
          }
        }
        getTicketSRs(data.ticketId).then((srs) => {
          setServiceRequests(srs);
        });
        setData(data);
      })
      .catch((_) => alertError("Couldn't fetch Ticket Details"));
  }, [baseData]);

  // javascript function to remove duplicates from array

  return (
    <div className="w-full ">
      <div className="flex flex-col md:flex-row p-4 items-center">
        {(data.mediaUrls || data.ticketMedia) && (
          <>
            <div className="flex gap-2">
              {[
                ...new Set(
                  (data.mediaUrls || [])
                    .concat(data.ticketMedia || [])
                    .filter(
                      (d) => !(data.blacklistedTicketMedia || []).includes(d)
                    )
                ),
              ]?.map((attachment, i) => {
                return (
                  <div
                    key={i}
                    className="text-sm mt-1"
                    onClick={(_) =>
                      setLightboxImages((_) => ({
                        images: (data.mediaUrls || []).concat(data.ticketMedia),
                        photoIndex: i,
                      }))
                    }
                  >
                    <PreviewLink url={attachment} tileView />
                  </div>
                );
              })}
            </div>
          </>
        )}
      </div>

      <div className="flex flex-col md:flex-row justify-between items-center">
        <div className="flex flex-col md:flex-row items-center">
          <p className="font-semibold text-newBlue-400">
            <div className="flex flex-row md:flex-col">
              <div className="flex flex-row items-center px-2 justify-center">
                <NotepadIcon />
                <span className="px-1 font-semibold text-2xl" style={{}}>
                  {baseData.ticketId
                    ? `Ticket #T${baseData.ticketId}`
                    : "Ticket"}
                </span>
              </div>
              {data.type === "EXTERNAL" && (
                <div className="flex justify-center w-20 h-6 rounded bg-newOrange-200 text-newOrange-200 bg-opacity-20 text-xs font-normal py-0.5 ml-6 my-1">
                  External
                </div>
              )}
            </div>
          </p>
          {data.estimateStatus && (
            <div className="px-2">
              <span className="inline-flex items-center rounded px-1 py-0.5 text-xs font-medium text-newBlue-400 bg-newYellow-400">
                {estimateStatusFormatted[data.estimateStatus]}
              </span>
            </div>
          )}
        </div>
        <div className="grid gap-2 items-center mt-3 grid-rows-2 grid-cols-2 md:flex md:flex-row md:gap-0">
          {data.type === "EXTERNAL" && !data.childTicket && (
            <div className="px-2">
              <button
                className="w-36 h-14 bg-transparent text-newBlue-400 font-semibold  py-2 px-4 border border-newBlue-400  rounded text-sm"
                onClick={() => {
                  setShowAssignTicket(true);
                }}
              >
                <div className="flex flex-row items-center">
                  <PenIcon className="h-4 w-4" />
                  <span className="px-1">Assign Ticket</span>
                </div>
              </button>
            </div>
          )}
          
          <div className="px-2">
            <button
              className="w-32 h-14 bg-transparent text-newBlue-400 font-semibold  py-2 px-4 border border-newBlue-400  rounded text-sm"
              onClick={() => {
                quickMessage({
                  contacts: [
                    data?.primaryContact,
                    ...conditionalArrayEntry(
                      data.alternateContact?.contactId !==
                        data.primaryContact?.contactId,
                      data.alternateContact
                    ),
                  ],
                  clientId: data?.createdUnderClientId,
                });
              }}
            >
              <div className="flex flex-row items-center">
                <ChatBubbleIcon />
                <span className="px-1">Quick Message</span>
              </div>
            </button>
          </div>

          {data.estimateId && (
            <div className="px-2">
              <button
                className="w-32 h-14 bg-transparent text-newBlue-400 font-semibold  py-2 px-2 border border-newBlue-400  rounded text-sm"
                onClick={() => {
                  setShowModal(true);
                }}
              >
                <div className="flex flex-row items-center">
                  <PenIcon className="h-4 w-4" />
                  <span className="px-1">View/Update Estimate</span>
                </div>
              </button>
            </div>
          )}
          <div className="px-2">
            <button
              className="w-32 h-14 bg-transparent text-newBlue-400 font-semibold  py-2 px-4 border border-newBlue-400  rounded text-sm"
              onClick={() => {
                setShowInvoice(true);
              }}
            >
              <div className="flex flex-row items-center">
                <InvoiceIcon className="h-4 w-4" />
                <span className="px-1">View Invoices</span>
              </div>
            </button>
          </div>
          {!(data.toDo === true && currentUser.id !== data.ownerId) && (
            <div className="px-2">
              <button
                className="w-32 h-14 bg-newBlue-400 text-white font-semibold  py-2 px-4 border border-newBlue-400 hover:border-transparent rounded text-sm"
                onClick={() => {
                  if (currentUser.id !== data.ownerId)
                    withConfirmation({
                      title: "Add to Todo",
                      description:
                        "Adding this Ticket to your ToDo list would make you the Ticket Owner. Would you like to continue?",
                      onConfirm: () => {
                        markTodo(data.ticketId, true)
                          .then((_) => {
                            onSuccessCB && onSuccessCB();
                          })
                          .catch((_) => {
                            alertError("Couldn't Add this ticket to To Do");
                          });
                      },
                    });
                  else
                    markTodo(data.ticketId, !data.toDo)
                      .then((_) => {
                        onSuccessCB && onSuccessCB();
                      })
                      .catch((_) => {
                        alertError("Couldn't Add this ticket to To Do");
                      });
                }}
              >
                <div className="flex flex-row items-center" onClick={() => {}}>
                  <AddIcon />
                  <span className="">
                    {data.toDo && currentUser.id === data.ownerId
                      ? "Mark as Done"
                      : "Add to Todo"}
                  </span>
                </div>
              </button>
            </div>
          )}
        </div>
      </div>

      <div className="px-2 flex flex-col md:flex-row py-4 gap-5 items-start">
        <div className="flex flex-col border border-newGray-600 w-full md:w-2/6 rounded">
          {ticketEdit ? (
            <>
              <MaterialForm
                data={data}
                renderArray={ticketForm}
                onSubmit={onSubmit}
                onChange={onChange}
              />
              <div className="flex flex-row-reverse">
                <Button
                  color="primary"
                  className="float-right p-4 ticket-save-button"
                  onClick={onSubmit}
                >
                  Save
                </Button>
                <Button
                  color="primary"
                  className="float-right p-4 ticket-save-button"
                  onClick={() => {
                    setTicketEdit((current) => !current);
                  }}
                >
                  Cancel
                </Button>
              </div>
            </>
          ) : (
            <>
              <div className="flex flex-row justify-between items-center p-4 border-b">
                <div className="font-semibold text-sm">Ticket Info</div>
                <div
                  className="text-newBlue-400 font-semibold text-sm cursor-pointer"
                  onClick={() => {
                    setTicketEdit((current) => !current);
                  }}
                >
                  Edit
                </div>
              </div>
              {[
                { label: "Title", value: data.title },
                { label: "Priority", value: data.priority ? "High" : "Low" },
                {
                  label: "Unit Number",
                  value: data?.unitNumber
                    ? data?.unitNumber
                    : data?.alternateContact?.unitNumber
                    ? data?.alternateContact?.unitNumber
                    : "----",
                },
                { label: "Notes", value: data.notes || "----" },
                {
                  label: "Stage",
                  value: pipelines
                    ?.filter((item) => {
                      return item.pipelineId === data.pipelineId;
                    })[0]
                    ?.stages?.filter((stage) => {
                      return stage.stageId === data.stageId;
                    })[0]?.stage,
                },
                ...(data.marketingCampaignName
                  ? [
                      {
                        label: "Marketing Campaign",
                        value: data.marketingCampaignName,
                      },
                    ]
                  : []),
              ].map((item, index) => (
                <div className="mt-6 border-b p-1 px-4">
                  <div className="font-semibold text-sm">{item.label}</div>
                  <div className="text-newGray-800">{item.value}</div>
                </div>
              ))}
            </>
          )}
        </div>

        <div className="w-full md:w-4/6">
          <div className="flex flex-col border border-newGray-600 rounded">
            <div className="flex flex-row justify-between items-center p-4 border-b">
              <div className="font-semibold text-sm">Client Details</div>
              <div
                onClick={() => {
                  setChangeOwner({
                    ticket: data.ticketId,
                    ownerId: data.ownerId,
                    display: true,
                  });
                }}
              >
                <TicketOwner ticket={data} />
              </div>
            </div>
            <DetailsSection
              attributes={[
                {
                  id: "clientId",
                  label: "Client",
                  value: data.clientName,
                  href: data.createdUnderClientId
                    ? `/commercial/${data.createdUnderClientId}/summary`
                    : `/individual/${data.createdUnderContactId}/buildings/${data.buildingId}`,
                  getOptions: () => getBuildingClients(data.buildingId),
                  transform: getClientOptions,
                },
                {
                  id: "primaryContactId",
                  label: "Primary Contact",
                  value: properStrings([
                    data.primaryContact?.firstName,
                    data.primaryContact?.lastName,
                  ]),
                  href: data.createdUnderClientId
                    ? `/commercial/${data?.createdUnderClientId}/messages/${data?.primaryContact?.contactId}`
                    : `/individual/${data?.createdUnderContactId}/messages/${data?.primaryContact?.contactId}`,
                  options: contacts,
                  transform: (contacts) =>
                    contacts.map((contact: any) => ({
                      label: properStrings([
                        contact.firstName,
                        contact.lastName,
                      ]),
                      value: contact.contactId,
                    })),
                },
                {
                  id: "buildingId",
                  label: "Building Address",
                  value: (
                    <div className="flex gap-2 items-center">
                      {buildingData.address?.geocodeLatitude &&
                        buildingData.address?.geocodeLongitude && (
                          <a
                            href={
                              "https://www.google.com/maps/search/?api=1&query=" +
                              buildingData.address?.geocodeLatitude +
                              "," +
                              buildingData.address?.geocodeLongitude
                            }
                            target="_blank"
                            rel="noreferrer noopener"
                            className="rounded flex flex-row items-center text-sm   "
                            onClick={(e) => e.stopPropagation()}
                          >
                            {" "}
                            <img
                              src={googleImage}
                              className="h-5 w-5"
                              alt="Map"
                            />
                          </a>
                        )}
                      {renderAddress(buildingData.address)}
                    </div>
                  ),
                  href: `/building/${data?.buildingId}/summary`,
                  getOptions: () =>
                    data.createdUnderContactId
                      ? getBuildingsUnderIndividualClient(
                          data.createdUnderContactId
                        )
                      : getBuildingsUnderCommercialClient(
                          data.createdUnderClientId
                        ),
                  transform: (buildings) =>
                    buildings.map((building: any) => ({
                      label: renderAddress(building.address),
                      value: building.buildingId,
                    })),
                },
                {
                  id: "alternateContactId",
                  label: "Alternate Contact",
                  value:
                    data.alternateContact?.firstName ||
                    data.alternateContact?.lastName
                      ? properStrings([
                          data.alternateContact?.firstName,
                          data.alternateContact?.lastName,
                        ])
                      : "----",
                  href: data.createdUnderClientId
                    ? `/commercial/${data?.createdUnderClientId}/messages/${data?.alternateContact?.contactId}`
                    : `/individual/${data?.createdUnderContactId}/messages/${data?.alternateContact?.contactId}`,
                  options: contacts,
                  transform: (contacts) =>
                    contacts.map((contact: any) => ({
                      label: properStrings([
                        contact.firstName,
                        contact.lastName,
                      ]),
                      value: contact.contactId,
                    })),
                },
              ]}
              refreshCB={(partialObj) => {
                const refresh = () => fetchTicketDetail(data.ticketId, setData);
                if (partialObj)
                  updateTicket(patchTicket(data, partialObj))
                    .then(() => {
                      refresh();
                    })
                    .catch((err) => {
                      alertError("Couldn't Update Ticket");
                    });
                else refresh();
              }}
              editContractorName={() => setShowAssignTicket(true)}
              isExternal={data?.type === "EXTERNAL"}
              contactorName={data?.childTicket?.vendor?.name}
            />
          </div>
          <div className="flex flex-row justify-between items-center py-6">
            <div className="font-semibold">Service Requests</div>
            {data.type !== "EXTERNAL" && (
              <div
                className="text-newBlue-400 font-semibold"
                style={{
                  fontSize: "14px",
                }}
              >
                <div
                  className="flex flex-row items-center cursor-pointer"
                  onClick={() => {
                    setAction("CREATE_SR");
                  }}
                >
                  <AddIconBlue className="h-4 w-4" />
                  <span className="px-1">Create a New SR</span>
                </div>
              </div>
            )}
          </div>
          <div className="flex flex-col">
            {action && action === "CREATE_SR" ? (
              <CreateSR
                baseData={data}
                successCB={(_) => {
                  setAction();
                  fetchServiceRequests(data.ticketId, setServiceRequests);
                  fetchTicketDetail(data.ticketId, setData);
                }}
                onCancelCB={(_) => setAction()}
              />
            ) : serviceRequests?.length === 0 ? (
              <span className="p-2 bg-gray-500 opacity-60 rounded-md text-black">
                {data.type !== "EXTERNAL"
                  ? `No Service Requests to show`
                  : `Service are yet to be identified by the Contractor`}
              </span>
            ) : (
              <>
                {serviceRequests ? (
                  <>
                    {serviceRequests?.map((item, index) => (
                      <div className="py-2">
                        <ServiceRequestTile
                          serviceRequest={item}
                          data={data}
                          srSelectCB={srSelectCB}
                        />
                      </div>
                    ))}
                  </>
                ) : (
                  <div className="flex justify-center">
                    <LoadingIcon className="h-4 relative bottom-2" />
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </div>
      <MaterialModal
        open={showModal}
        setOpen={(_) => {
          setShowModal((current) => !current);
        }}
        label="estimate-modal"
        describedBy="create-view-estimate"
        width={80}
        className="px-2 py-2"
      >
        <EstimateForm
          ticketData={data}
          setShowModal={setShowModal}
          onSuccessCB={onSuccessCB}
          refreshCB={refreshCB}
          isExternal={data.type === "EXTERNAL"}
        />
      </MaterialModal>
      <MaterialModal
        open={showAssignTicket}
        setOpen={(_) => {
          setShowAssignTicket((current) => !current);
        }}
        label="assign-modal"
        describedBy="assign-ticket"
      >
        <AssignTicket
          ticketId={data.ticketId}
          onSuccess={() => {
            setShowAssignTicket(false);
            getTicket(data.ticketId).then((data) => {
              setData(data);
            });
          }}
        />
      </MaterialModal>

      {srForUpdate && (
        <NewServiceRequestPopup
          data={srForUpdate}
          clearCB={() => {
            setSrForUpdate((_) => undefined);
          }}
          backCB={() => {
            setSrForUpdate((_) => undefined);
          }}
        />
      )}
      <MaterialModal
        open={showInvoice}
        setOpen={(_) => {
          setShowInvoice((current) => !current);
        }}
      >
        <ListInvoices ticketId={data.ticketId} />
      </MaterialModal>
      {lightboxImages && (
        <MaterialModal
          className="p-0"
          open={lightboxImages ? true : false}
          setOpen={() => {
            setLightboxImages((_) => undefined);
          }}
        >
          <Carousel lightboxImages={lightboxImages} />
        </MaterialModal>
      )}

      {changeOwner.display && (
        <MaterialModal
          open={changeOwner.display}
          setOpen={(_) =>
            setChangeOwner((current) => {
              return {
                ...current,
                display: false,
              };
            })
          }
          label="new-user-modal"
          describedby="create-new-user"
        >
          <SelectOwner
            ticketId={changeOwner.ticket}
            currentOwner={changeOwner.ownerId}
            territory={territory}
            changeOwnerCB={(data) => {
              assignOwner(data).then(() => {
                fetchTicketDetail(changeOwner.ticket, setData);
                setChangeOwner((current) => {
                  return {
                    ...current,
                    display: false,
                  };
                });
              });
            }}
          />
        </MaterialModal>
      )}
    </div>
  );
}