import React, { useEffect, useState } from "react";

import DeleteIcon from "@mui/icons-material/Delete";

import { alertError, alertSuccess } from "../../actions/AlertActions";
import { useAppActions } from "../../actions/AppActions";
import { createUnderHOA, createUnderPMC, removeBuilding } from "../../api/Api";
import { ValidLink } from "../../utils/RouterUtils";
import { deepFilter, deepUpdate } from "../../utils/StateUtils";
import { renderAddress } from "../../utils/StringUtils";
import { AddIconBlue, SearchIcon, Tick } from "../common/AppIcons";
import MaterialModal from "../common/MaterialModal";
import { BuildingIcon } from "./ClientAppIcons";
import BuildingFormFields from "./forms/BuildingFormFields";

export default function BuildingDiv({
  buildings,
  clientData,
  onSelectCB,
  refreshCB,
}) {
  const [searchText, setSearchText] = useState("");
  const [buildingData, setBuildingData] = useState(buildings);

  const [buildingFormState, setBuildingFormState] = useState();
  useEffect(() => {
    setBuildingData(buildings || []);
  }, [buildings]);

  const { withConfirmation } = useAppActions();

  const deepSearch = deepFilter(searchText);
  return (
    <div className="border-newGray-1500 items-center py-4 p-4 rounded-lg border w-full max-h-112 overflow-auto">
      <div className="flex flex-row justify-between items-center py-2">
        <div className="flex flex-row flex-wrap gap-4 justify-between items-start w-full">
          <div className="flex gap-4 items-center">
            <span className="font-semibold">Buildings</span>
            <button
              className="border rounded text-sm px-2 py-1 bg-newBlue-500 text-newBlue-400"
              onClick={() => {
                setBuildingFormState({});
              }}
            >
              <div className="flex flex-row items-center">
                <div>
                  <AddIconBlue className="h-4 w-4" />
                </div>
                <div className="flex gap-1 px-2">
                  <span className="hidden md:inline">Add</span>
                  <span className="">New</span>
                  <span className="hidden md:inline">Building</span>
                </div>
              </div>
            </button>
          </div>
          <div className="flex flex-row items-center px-2 rounded bg-newGray-1400 ">
            <div>
              <SearchIcon />
            </div>
            <input
              id="buildings_search"
              className="focus:outline-none p-2 text-gray-700 bg-newGray-1400 w-52"
              placeholder="Search..."
              value={searchText}
              onChange={(e) => {
                setSearchText(e.target.value);
              }}
            />
          </div>
        </div>
      </div>
      {buildingData
        ?.filter((building) => deepSearch(building))
        ?.map((building, _index) => (
          <div className="flex my-4 p-2 justify-between items-start">
            <ValidLink
              key={building.buidlingId}
              href={"buildings/" + building.buildingId}
              className={
                "cursor-pointer rounded flex flex-row px-2 py-1 hover:bg-newBlue-500 hover:text-newBlue-400 items-center"
              }
              onClick={() => {
                onSelectCB(building);
              }}
            >
              <div className="flex items-center">
                <div className="mr-0.5">
                  <BuildingIcon />
                </div>
                <p className="px-2">
                  {renderAddress(building.address)}
                  {building.address?.melissaBaseMak && (
                    <span className="mx-2 bg-green-500 text-white h-3.5 w-3.5 rounded-full inline-flex items-center justify-center">
                      <Tick className="h-2 w-2" />
                    </span>
                  )}
                </p>
              </div>
            </ValidLink>
            <button
              className="border rounded text-sm px-2 py-1 bg-newGray-700 text-newGray-800"
              onClick={() => {
                withConfirmation({
                  title: "Remove Building",
                  description: `Are you sure you want to remove ${renderAddress(
                    building.address
                  )}
                This will remove this building and the client/manager will no longer be able to access the building.
                
                This action cannot be undone.`,
                  onConfirm: () =>
                    removeBuilding(building.buildingId, clientData.clientId)
                      .then((_res) => {
                        alertSuccess("Building removed successfully");
                        refreshCB();
                      })
                      .catch((_err) => {
                        alertError("Failed to remove building");
                      }),
                });
              }}
            >
              <div className="flex flex-col md:flex-row items-center w-12 md:w-20">
                <div>
                  <DeleteIcon className="h-4 w-4" />
                </div>
                <div className="px-2">Remove</div>
              </div>
            </button>
          </div>
        ))}

      {buildingFormState && (
        <MaterialModal
          open={true}
          setOpen={() => {
            setBuildingFormState();
          }}
        >
          <BuildingFormFields
            data={buildingFormState}
            onChange={({ name, value }) => {
              setBuildingFormState((clientData) =>
                deepUpdate(name, value, clientData)
              );
            }}
          />
          <div className="flex flex-row justify-end">
            <button
              className="border rounded text-sm px-2 py-1 h-10 w-24 mr-10 bg-newBlue-500 text-newBlue-400"
              onClick={() => {
                let createBuildingAPI =
                  clientData.clientClass === "HOA_OWNER"
                    ? createUnderHOA
                    : createUnderPMC;

                createBuildingAPI(
                  {
                    ...buildingFormState,
                    contacts: [],
                  },
                  clientData.clientId
                )
                  .then((_newBuilding) => {
                    alertSuccess("Successfully saved the building!");
                    setBuildingFormState();
                    refreshCB();
                  })
                  .catch((_err) => {
                    alertError(
                      "Sorry, we were unable to create the building. Please make sure all the fields are filled out correctly."
                    );
                  });
              }}
            >
              Save
            </button>
          </div>
        </MaterialModal>
      )}
    </div>
  );
}
