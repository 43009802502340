import React, { useEffect, useRef, useState } from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";

import CloseIcon from "@mui/icons-material/Close";
import DescriptionIcon from "@mui/icons-material/Description";
import { Button, TextField } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import Switch from "@mui/material/Switch";
import moment from "moment";
import "moment-timezone";
import { Link, navigate } from "raviger";

import {
  alertError,
  alertInfo,
  alertSuccess,
} from "../../actions/AlertActions";
import { useAppActions } from "../../actions/AppActions";
import {
  createSR,
  getAssociatesByTerritory,
  getBuilding,
  getClient,
  getSR,
  listSupplier,
  updateStatusCSR,
  getMyVendor,
  getTicket,
} from "../../api/Api";
import { conditionalArrayEntry } from "../../utils/ObjectUtills";
import { deepUpdate } from "../../utils/StateUtils";
import {
  properString,
  properStrings,
  renderAddress,
} from "../../utils/StringUtils";
import SRGallery from "../clients/SRGallery";
import { make as MaterialForm } from "../common/form/MaterialForm.bs";
import { formUpdateSRTicket } from "../common/form/constants";
import TaskTicker from "../pipelines/TaskTicker";
import NewMaterialsServiceRequestScreen from "../service/NewMaterialsServiceRequestScreen";
import {
  getAppointmentSteps,
  getCurrentStep,
} from "../service/RenderAppointments";
import ScheduleForm from "../service/ScheduleForm";
import AddressLink from "../tickets/AddressLink";
import TicketOwner from "../tickets/TicketOwner";
import {
  AddIconBlue,
  ClockIcon,
  LeftArrowWithTailIcon,
  NotepadIcon,
  PenIcon,
  WorkerIcon,
} from "./AppIcons";
import Carousel from "./Carousel";
import { DraggableItem } from "./DraggableItem";
import MaterialModal from "./MaterialModal";
import { NewMediaDragIn } from "./NewMediaDragIn";
import PreviewLink from "./PreviewLink";
import { JobTypesDict } from "./form/constants";

const appointmentType = {
  PICKUP_AND_SERVICE: "Pickup and Service",
  PICKUP: "Pickup",
  SERVICE: "Service",
};

export default function NewServiceRequestPopup({
  data,
  backCB,
  clearCB,
  serviceData = undefined,
}) {
  const fileRef = useRef();

  const [serviceRequest, setServiceRequest] = useState(data);
  const [srEdit, setSREdit] = useState(false);
  const [suppliers, setSuppliers] = useState([]);

  const [imagePopUp, setImagePopUp] = useState({
    display: false,
    photoIndex: null,
  });

  const [lightboxImages, setLightboxImages] = useState([]);
  const [editAppointment, setEditAppointment] = useState();
  const [associates, setAssociates] = useState([]);
  const [changeContacts, toggleChangeContacts] = useState(false);
  const [contacts, setContacts] = useState([]);
  const [changeBuilding, toggleChangeBuilding] = useState(false);
  const [clientBuildings, setClientBuildings] = useState();
  const [galleyModal, setGalleryModal] = useState(false);
  const [tempImages, setTempImages] = useState([]);
  const [selectedImage, setSelectedImage] = useState();

  const [buildingData, setBuildingData] = useState([]);
  const [modal, setModal] = useState(false);

  const [state, setState] = useState({});
  const [attachments, setAttachments] = useState([]);

  const [appointments, setEditAppointments] = useState([]);
  const [skillData, setSkillData] = useState([]);
  const [isExternal, setIsExternal] = useState(false);

  const { openTicket } = useAppActions();

  useEffect(() => {
    let sorted = (serviceRequest.appointments ?? [])?.sort(function (a, b) {
      var x = a["timelineStart"];
      var y = b["timelineStart"];
      return x < y ? -1 : x > y ? 1 : 0;
    });

    sorted = sorted.reverse();

    setEditAppointments(sorted ? sorted : []);
  }, [serviceRequest]);

  useEffect(() => {
    setServiceRequest(data);
  }, [data]);

  useEffect(() => {
    getSR(data.serviceRequestId).then((data) => {
      setIsExternal(data.ticketType === "EXTERNAL");
    });
    getMyVendor()
      .then((data) => {
        setSkillData(data.categories);
      })
      .catch(() => alertError("Error while fetching vendor"));
  }, []);

  const getSkills = () => {
    return skillData.map((item) => {
      return { label: properString(item.skill), value: item.skill };
    });
  };

  const getAppointmentStatus = (currentStatus, appointmentType) => {
    let found = false;
    let value_list = { ENROUTE: 1, STARTED: 2, FINISHED: 3 };

    if (appointmentType === "PICKUP_AND_SERVICE") {
      value_list = { ENROUTE: 1, PICKED_UP: 2, STARTED: 3, FINISHED: 4 };
    }

    const value = value_list[currentStatus];

    let return_status = [];

    let status = [
      { label: "STARTED", value: "STARTED" },
      { label: "FINISHED", value: "FINISHED" },
    ];

    if (appointmentType === "PICKUP_AND_SERVICE") {
      status = [
        { label: "PICKED UP", value: "PICKED_UP" },
        { label: "STARTED", value: "STARTED" },
        { label: "FINISHED", value: "FINISHED" },
      ];
    } else if (appointmentType === "PICKUP") {
      status = [
        { label: "PICKED UP", value: "STARTED" },
        { label: "DELIVERED", value: "FINISHED" },
      ];
    }

    if (currentStatus) {
      if (currentStatus !== "STARTED" && currentStatus !== "PICKED_UP") {
        status = [{ label: "ENROUTE", value: "ENROUTE" }, ...status];
      }
    }

    if (!value) {
      return status;
    } else {
      status.map((item, index) => {
        if (index + 2 > value) {
          return_status.push(item);
        }
      });

      return return_status;
    }
  };

  const renderAppointmentStatus = (appointment, status) => {
    if (
      status === "PICKED_UP" &&
      appointment.appointmentType === "PICKUP_AND_SERVICE"
    ) {
      return "Picked Up";
    }

    if (appointment.appointmentType === "PICKUP") {
      if (status === "STARTED") {
        return "Picked Up";
      } else if (status === "FINISHED") {
        return "Delivered";
      }
    }

    return properString(status);
  };

  const addEncodedFile = (setState, file) =>
    function (upload) {
      setState((attachments) => {
        return {
          ...state,
          attachments: [
            ...state.attachments,
            {
              fileName: file.name,
              contentType: file.type,
              encodedFile: upload.target.result,
            },
          ],
        };
      });
    };

  const handleSelectFile = (event) => {
    // console.log("Uploading to S3");
    var files = event.target.files;
    Array.from(files).forEach((file) => {
      // console.log("MIME:" + file.type);
      var reader = new FileReader();
      reader.onload = addEncodedFile(setState, file);
      reader.readAsDataURL(file);
    });
  };

  const addAttachmentToSR = (url) => {
    setServiceRequest((serviceRequest) => {
      return {
        ...serviceRequest,
        mediaUrls: [...(serviceRequest.mediaUrls ?? []), url],
      };
    });
  };
  const onCancelCB = () => {
    console.log("cancelled called");
    setEditAppointment(undefined);
  };

  useEffect(() => {
    setTempImages(serviceRequest.mediaUrls || []);
  }, [serviceRequest]);

  const onSuccessCB = () => {
    // setForUpdate && setForUpdate((_) => null);
    // setNewSchedule && setNewSchedule((_) => null);
    setEditAppointment(undefined);
    refreshCB();
  };

  const refreshCB = () => {
    getSR(data.serviceRequestId).then((data) => {
      setServiceRequest(data);
    });
  };

  useEffect(() => {
    if (changeBuilding)
      getClient(serviceRequest.clientId)
        .then((clientData) => setClientBuildings(clientData.buildings))
        .catch((_) => {
          toggleChangeBuilding(false);
          alertError("Error Loading Client Buildings");
        });
  }, [changeBuilding]);

  useEffect(() => {
    if (serviceRequest?.buildingId)
      getBuilding(serviceRequest.buildingId)
        .then((building) => {
          setBuildingData(building);
          setContacts(building.contacts);
        })
        .catch((err) => {
          alertError("Couldn't Fetch Select Building");
        });
    else setContacts([]);
  }, [serviceRequest?.buildingId]);

  useEffect(() => {
    getAssociatesByTerritory(data.territory)
      .then((data) => {
        setAssociates(data);
      })
      .catch((error) => alertError("Couldn't get Associates"));
  }, []);

  useEffect(() => {
    if (data) {
      refreshCB();

      listSupplier()
        .then((suppliers) => {
          let options = [];
          suppliers.map((supplier) => {
            options.push({
              label: supplier.name,
              value: supplier.supplierId,
              tags: supplier.typeOfSupplies || [],
            });
          });
          setSuppliers(options);
        })
        .catch((_err) => {
          alertError(
            "Sorry, we couldn't populate suppliers. Please try again later."
          );
        });
    }
  }, [data, srEdit]);

  const convertToTimeZone = (date, timeZone) => {
    let timezone = serviceRequest.timezone;
    let requiredTimezoneOffset = moment().tz(timezone)?.utcOffset();

    return moment(date?.split("Z")[0])
      .add(requiredTimezoneOffset, "minutes")
      .format("MM/DD/YYYY hh:mm A");
  };

  const getAssociate = (appointment) => {
    let ids = [];
    appointment.map((item, index) => {
      ids.push(item.associateId);
    });
    return ids;
  };

  const goBack = backCB
    ? backCB
    : () => {
        clearCB();
        openTicket(serviceRequest.ticketId);
      };

  return (
    <MaterialModal
      open={serviceRequest ? true : false}
      setOpen={(_) => clearCB()}
      label="new-user-modal"
      describedby="create-new-user"
      containerClassName="md:w-8/12 h-4/5 md:h-auto md:max-h-screen overflow-y-auto"
    >
      <DndProvider backend={HTML5Backend}>
        <div className="flex flex-row-reverse justify-between p-4">
          <div className="flex flex-row items-center">
            <>
              <NewMediaDragIn
                urls={[
                  ...new Set(
                    (serviceRequest.mediaUrls || []).filter(
                      (d) =>
                        !(serviceRequest.blacklistedTicketMedia || []).includes(
                          d
                        )
                    )
                  ),
                ]}
                editMedia={true}
                setServiceRequest={setServiceRequest}
                OnDeleteCB={(filtered) => {
                  setServiceRequest((serviceRequest) => {
                    return {
                      ...serviceRequest,
                      mediaUrls: filtered,
                    };
                  });

                  let data = serviceRequest;
                  data["mediaUrls"] = filtered;
                  delete data["appointments"];
                  createSR(data)
                    .then((_) => {
                      setSREdit(false);
                      // setForUpdate();
                      alertSuccess("Successfully Updated Service Request");
                    })
                    .catch((_) => alertError("Error Updating Service Request"));
                }}
              />
            </>
          </div>
          <button
            className="bg-gray-200 p-2 rounded-lg"
            onClick={() => {
              goBack();
            }}
          >
            <div className="flex flex-row items-center">
              <div>
                <LeftArrowWithTailIcon className="h-4 w-4" />
              </div>
              <div className="px-1 text-sm" style={{ color: "#6B6C7B" }}>
                Go back
              </div>
            </div>
          </button>
        </div>

        <div className="flex flex-col md:flex-row justify-between items-center px-4">
          <div className="flex flex-row items-center">
            <p className="font-semibold flex flex-row gap-4 md:gap-0 items-center text-newBlue-400 mb-3">
              <div className="flex flex-row items-center">
                <NotepadIcon />
                <span className="px-1 font-semibold text-2xl" style={{}}>
                  SR #
                  {data.serviceRequestId
                    ? `${data.ticketId}-` +
                      (!data.isDraft
                        ? `SR${data.serviceRequestIndex}`
                        : `I${data.serviceRequestIndex}`)
                    : ""}
                </span>
              </div>
              <TicketOwner ticket={data} />
            </p>
          </div>

          {/* Actions Section */}
          {/* Buttons for Edit Media, Add Materials, Create Appointment */}
          <div className="md:flex md:flex-row gap-2 justify-around items-center grid grid-rows-2 grid-cols-2 gap-x-2">
            {[
              ...conditionalArrayEntry(!isExternal, {
                label: "Edit Media",
                onClick: () => {
                  setGalleryModal(true);
                },
              }),
              ...conditionalArrayEntry(!isExternal, {
                label: "Add Materials",
                onClick: () => {
                  // TODO: Fix hack for invoking the modal from the parent
                  // Consider rebuilding the ServiceRequest Popup
                  document.getElementById("create-materials").click();
                },
              }),
              ...conditionalArrayEntry(!isExternal, {
                label: "Create Appointment",
                onClick: () => {
                  setEditAppointment({
                    serviceRequestId: serviceRequest.serviceRequestId,
                    associateId: [],
                    startTime: serviceRequest.preferredDateTime,
                    timezone: serviceRequest.timezone,
                  });
                },
              }),
              {
                label: "Remedial",
                onClick: () => {
                  alertInfo("This feature is not yet available in Squad Hub");
                },
              },
            ].map((item, index) => (
              <button
                className="w-32 h-14 bg-transparent text-newBlue-400 font-semibold  py-2 px-4 border border-newBlue-400  rounded text-sm md:w-auto"
                onClick={item.onClick}
              >
                {item.label}
              </button>
            ))}
          </div>
        </div>

        <div className="m-2 border border-newGray-700 rounded">
          <div className="p-2 flex flex-col md:flex-row gap-4 md:gap-0 ml-3 md:ml-0 justify-between items-start">
            {[
              {
                label: "Client Name",
                value: serviceRequest.clientName || "----",
                href: serviceRequest.clientId
                  ? `/commercial/${serviceRequest.clientId}/summary`
                  : `/individual/${serviceRequest.contactId}/buildings`,
              },
              {
                label: "Primary Contact",
                value: properStrings([
                  serviceRequest.contactFirstName,
                  serviceRequest.contactLastName,
                ]),
              },
            ].map((item) => (
              <div className="p-1">
                <div className="font-semibold text-sm">{item.label}</div>
                <Link
                  className={
                    "text-newGray-800 " + (item.onClick ? "cursor-pointer" : "")
                  }
                  href={item.href ?? "#"}
                >
                  {item.value}
                </Link>
              </div>
            ))}
            <div className="p-1">
              {changeBuilding && clientBuildings ? (
                <MaterialForm
                  data={{}}
                  renderArray={[
                    {
                      name: "building",
                      label: "Building",
                      inputType: "SELECT",
                      options: clientBuildings.map((building) => {
                        return {
                          label: renderAddress(building.address),
                          value: building.buildingId,
                        };
                      }),
                    },
                  ]}
                  onSubmit={(_) => {}}
                  onChange={({ name, value }) => {
                    var data = Object.assign({}, serviceRequest);
                    delete data["appointments"];

                    createSR({ ...data, buildingId: value })
                      .then(() => {
                        refreshCB();
                        alertSuccess("Successfully Updated Service Request");
                      })
                      .catch((_) =>
                        alertError("Error Updating Service Request")
                      );
                    toggleChangeBuilding((_) => false);
                    setClientBuildings((_) => undefined);
                  }}
                />
              ) : (
                <>
                  <div className="flex flex-row gap-2 items-center">
                    <div className="font-semibold text-sm">
                      {"Building Address"}
                    </div>
                    {!isExternal && (
                      <div
                        className="px-1 cursor-pointer"
                        onClick={() => {
                          toggleChangeBuilding((_) => true);
                        }}
                      >
                        <PenIcon className="h-4 w-4" />
                      </div>
                    )}
                  </div>
                  <div className="text-newGray-800 flex flex-row items-center">
                    <AddressLink
                      address={buildingData.address}
                      buildingId={serviceRequest.buildingId}
                    />
                  </div>
                </>
              )}
            </div>
            <div className="p-1 w-56">
              {changeContacts ? (
                <>
                  {/* <MaterialForm
                  data={{}}
                  renderArray={[
                    {
                      name: "contactId",
                      label: "Contact",
                      inputType: "SELECT",
                      options: contacts.map((contact) => {
                        return {
                          label: properStrings([
                            contact.firstName,
                            contact.lastName,
                            contact.unitNumber,
                          ]),
                          value: contact.contactId,
                        };
                      }),
                    },
                  ]}
                  onSubmit={(_) => {}}
                  onChange={({ name, value }) => {
                    let data = Object.assign({}, serviceRequest);
                    delete data["appointments"];

                    createSR({
                      ...data,
                      alternateContactId: value,
                    }).then(() => {
                      refreshCB();
                    });

                    toggleChangeContacts((_) => false);
                  }}
                /> */}
                </>
              ) : (
                <>
                  <div className="flex flex-row gap-2 items-center">
                    <div className="font-semibold text-sm">
                      {"Alternate Contact"}
                    </div>
                    {/* <div
                      className="px-1 cursor-pointer"
                      onClick={() => {
                        toggleChangeContacts((_) => true);
                      }}
                    >
                      <PenIcon className="h-4 w-4" />
                    </div> */}
                  </div>
                  <div className="text-newGray-800 flex flex-row items-center">
                    <div>
                      {properStrings([
                        serviceRequest.alternateContactFirstName,
                        serviceRequest.alternateContactLastName,
                      ]) || "----"}
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
        <div className="px-2 flex flex-col md:flex-row gap-5 items-start">
          <div className="flex flex-col gap-2 w-full md:w-2/6">
            {srEdit ? (
              <>
                <MaterialForm
                  data={serviceRequest}
                  renderArray={formUpdateSRTicket(
                    serviceRequest,
                    getSkills(),
                    skillData
                  )}
                  onChange={(update) => {
                    const { name, value } = update;
                    setServiceRequest((data) => deepUpdate(name, value, data));
                  }}
                />
                <Button
                  onClick={(_) => {
                    let data = serviceRequest;
                    delete data["appointments"];
                    createSR(data)
                      .then((_) => {
                        setSREdit(false);
                        // setForUpdate();
                        alertSuccess("Successfully Updated Service Request");
                      })
                      .catch((_) =>
                        alertError("Error Updating Service Request")
                      );
                  }}
                  color="primary"
                  className="float-right p-4 sr-update-button"
                >
                  Update
                </Button>
              </>
            ) : (
              <div className="border border-newGray-700 rounded">
                <div className="flex flex-row justify-between items-center p-4 border-b">
                  <div className="font-semibold text-sm">SR Details</div>
                  {!isExternal && (
                    <div
                      className="text-newBlue-400 font-semibold text-sm cursor-pointer"
                      onClick={() => {
                        setSREdit((current) => !current);
                      }}
                    >
                      Edit
                    </div>
                  )}
                </div>
                {[
                  {
                    label: "Job Type",
                    value: JobTypesDict[serviceRequest.jobType],
                  },
                  {
                    label: "Sub Category",
                    value: properString(serviceRequest.subCategory),
                  },
                  {
                    label: "Descripiton",
                    value: serviceRequest.description || "----",
                  },
                  {
                    label: "Preferred Date and Time",
                    value: serviceRequest.preferredDateTime
                      ? moment(
                          new Date(serviceRequest.preferredDateTime)
                        ).format("MM/DD/YYYY hh:mm A")
                      : "----",
                  },
                  {
                    label: "Duration",
                    value: serviceRequest.duration || "----",
                  },
                  {
                    label: "Unit Number",
                    value: serviceRequest.unitNumber || "----",
                  },
                  {
                    label: "Booking Status",
                    value: properString(serviceRequest.bookingStatus),
                  },
                ].map((item, index) => (
                  <div className="mt-6 border-b p-1 px-4">
                    <div className="font-semibold text-sm">{item.label}</div>
                    <div className="text-newGray-800 whitespace-pre-line">
                      {item.value}
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>

          <div className="md:w-4/6 mb-5">
            <NewMaterialsServiceRequestScreen
              serviceRequest={serviceRequest}
              serviceRequestId={serviceRequest.serviceRequestId}
              suppliers={suppliers}
              refreshCB={refreshCB}
            />
            <div className="flex flex-row justify-between items-center py-6">
              <div className="font-semibold">Appointments</div>
            </div>
            {appointments?.length === 0 && (
              <span className="p-2 mb-4 bg-gray-500 opacity-60 rounded-md text-black">
                {isExternal
                  ? `Appointments are yet to be scheduled by the Contractor`
                  : `No Appointments to show`}
              </span>
            )}
            {editAppointment ? (
              <ScheduleForm
                onCancelCB={onCancelCB}
                onSuccessCB={onSuccessCB}
                jobType={
                  serviceData ? serviceData.jobType : serviceRequest.jobType
                }
                materials={
                  serviceRequest.materials ? serviceRequest.materials.length : 0
                }
                duration={serviceRequest.duration}
                baseData={editAppointment}
                setEditAppointment={setEditAppointment}
                associates={associates}
              />
            ) : (
              <div className="flex flex-col ">
                {appointments?.map((appointment, appointments_index) => {
                  const beforeImages = [
                    ...(appointment.beforeUrls || []),
                    ...(serviceRequest.mediaUrls || []).filter(
                      (d) =>
                        !(serviceRequest.blacklistedTicketMedia || []).includes(
                          d
                        )
                    ),
                  ];
                  return (
                    <>
                      <div
                        key={appointments_index}
                        className="mt-3 sm:mb-4 w-full px-2 md:px-12 py-1 mx-auto flex flex-col items-start bg-white"
                      >
                        <div className="flex flex-row justify-between w-full items-center py-1">
                          <div className="flex flex-row items-center">
                            <div>
                              <span className="flex justify-center sm:whitespace-pre items-center rounded px-2 py-1 text-sm font-medium bg-newYellow-500 text-newBlue-600 w-24 md:w-auto h-14">
                                {appointmentType[appointment.appointmentType]}
                              </span>
                            </div>
                            <div className="px-2">
                              <button
                                className="flex flex-row items-center bg-gray-400 hover:bg-gray-400 rounded px-2 text-sm  py-1 w-24 h-14  "
                                onClick={() => {
                                  if (!isExternal) {
                                    if (appointment.status !== "FINISHED") {
                                      setState({
                                        appointmentType:
                                          appointment.appointmentType,
                                        appointmentId:
                                          appointment.appointmentId,
                                        status: appointment.status,
                                        attachments: attachments,
                                        notes: "",
                                        revisitRequired: false,
                                      });
                                      setModal(true);
                                    }
                                  }
                                }}
                              >
                                {!isExternal && (
                                  <div className="pr-2">
                                    <PenIcon className="w-4 h-4" />
                                  </div>
                                )}
                                {renderAppointmentStatus(
                                  appointment,
                                  appointment.status
                                )}
                              </button>
                            </div>
                            {appointment.remedial && (
                              <div className="">
                                <span className="inline-flex sm:whitespace-pre items-center rounded px-2 py-1 text-sm font-medium bg-red-400 text-newBlue-600 w-24 h-14">
                                  Remedial
                                </span>
                              </div>
                            )}
                          </div>
                          {!isExternal && (
                            <div>
                              <button
                                className="bg-newGray-900 px-2 rounded"
                                onClick={(_) => {
                                  let requiredTimezoneOffset = moment()
                                    .tz(serviceRequest.timezone)
                                    ?.utcOffset();

                                  setEditAppointment({
                                    appointmentType:
                                      appointment.appointmentType,
                                    appointmentId: appointment.appointmentId,
                                    shouldNotify: appointment.shouldNotify,
                                    serviceRequestId:
                                      serviceRequest.serviceRequestId,
                                    associateId: getAssociate(
                                      appointment.associates
                                    ),
                                    startTime: moment(
                                      appointment?.timelineStart?.split("Z")[0]
                                    ).add(requiredTimezoneOffset, "minutes"),
                                    endTime: moment(
                                      appointment?.timelineEnd?.split("Z")[0]
                                    ).add(requiredTimezoneOffset, "minutes"),
                                    timezone: serviceRequest.timezone,
                                    isRemedial: appointment.remedial,
                                    description:
                                      appointment.appointmentDescription,
                                  });
                                }}
                              >
                                <div className="flex flex-row items-center w-26 md:w-auto h-14">
                                  <div>
                                    <PenIcon className="w-4 h-4" />
                                  </div>
                                  <div className="text-sm">
                                    Edit Appointment
                                  </div>
                                </div>
                              </button>
                            </div>
                          )}
                        </div>
                      </div>
                      <div
                        key={appointments_index}
                        className=" border-b mt-3 sm:mb-4 w-full sm:max-w-5xl p-1 pb-10 sm:px-12 mx-auto flex flex-col items-start bg-white sm:rounded border-gray-200 rounded-md"
                      >
                        <div className="w-full flex flex-col gap-4 items-center">
                          <TaskTicker
                            className="flex"
                            tasks={getAppointmentSteps(
                              appointment.appointmentType
                            )}
                            last_finished={getCurrentStep(
                              appointment.appointmentType,
                              appointment.status
                            )}
                          />

                          <div className="mt-3 flex flex-row gap-3 sm:gap-8 items-center px-2 text-gray-700 text-sm uppercase">
                            <ClockIcon className="h-6 w-6" />
                            <div
                              style={{ fontSize: "15px" }}
                              className="flex flex-row items-center flex-wrap gap-x-2 px-2 py-1 rounded bg-gray-100"
                            >
                              {appointment.timelineStart && (
                                <span>
                                  {convertToTimeZone(
                                    appointment.timelineStart,
                                    serviceRequest.timezone
                                  )}
                                </span>
                              )}
                            </div>

                            <span>-</span>
                            <div
                              style={{ fontSize: "15px" }}
                              className="flex flex-row items-center flex-wrap gap-x-2 px-2 py-1 rounded bg-gray-100"
                            >
                              <span>
                                {convertToTimeZone(
                                  appointment.timelineEnd,
                                  serviceRequest.timezone
                                )}
                              </span>
                            </div>
                          </div>

                          <div className="flex flex-row gap-3 sm:gap-8 items-start px-2 text-sm text-gray-700 cursor-pointer">
                            <WorkerIcon className="h-4 w-4" />

                            <div className="flex flex-row">
                              {appointment.associates.map(
                                (associate, associate_index) => {
                                  return (
                                    <div
                                      className="hover:text-newBlue-200"
                                      onClick={() => {
                                        navigate(
                                          `/associates/${associate.associateId}/details`
                                        );
                                      }}
                                    >
                                      {associate.firstName
                                        ? associate.firstName + " "
                                        : ""}
                                      {associate.lastName
                                        ? associate.lastName
                                        : ""}
                                      {associate_index <
                                      appointment.associates.length - 1
                                        ? ", "
                                        : ""}
                                    </div>
                                  );
                                }
                              )}
                            </div>
                          </div>

                          {appointment.appointmentDescription && (
                            <div className="flex flex-row gap-3 sm:gap-8 items-start px-2 text-sm text-gray-700 cursor-pointer">
                              <DescriptionIcon />

                              <div className="flex flex-row">
                                {appointment.appointmentDescription}
                              </div>
                            </div>
                          )}

                          <div className="flex flex-col sm:grid sm:grid-cols-2 gap-3 justify-between w-full">
                            <div className="bg-gray-100 rounded p-5 flex flex-col gap-4">
                              <span>{"Before"}</span>
                              {/* Before Notes */}

                              {beforeImages.length > 0 ? (
                                <div
                                  className={
                                    "flex flex-row flex-wrap gap-1 items-start justify-start w-full m-auto"
                                  }
                                >
                                  {/* "object-cover rounded border-2 border-gray-100 hover:border-blue-600 cursor-pointer max-h-28" */}
                                  {beforeImages.map((attachment, i) => (
                                    <div
                                      key={i}
                                      className="text-sm mt-1"
                                      onClick={(_) => {
                                        setImagePopUp({
                                          display: true,
                                          photoIndex: i,
                                        });
                                        setLightboxImages([
                                          ...(appointment.beforeUrls || []),
                                          ...new Set(
                                            (
                                              serviceRequest.mediaUrls || []
                                            ).filter(
                                              (d) =>
                                                !(
                                                  serviceRequest.blacklistedTicketMedia ||
                                                  []
                                                ).includes(d)
                                            )
                                          ),
                                        ]);
                                      }}
                                    >
                                      <DraggableItem
                                        item={attachment}
                                        dragCB={addAttachmentToSR}
                                      >
                                        <PreviewLink url={attachment} />
                                      </DraggableItem>
                                    </div>
                                  ))}
                                </div>
                              ) : (
                                <div className="w-48 h-40 m-auto rounded text-sm flex flex-col gap-2 items-center justify-center text-red-600">
                                  {/* <WarningIcon className="w-5 h-5" /> */}
                                  <span className="text-red-400">
                                    Yet to be updated
                                  </span>
                                </div>
                              )}

                              {appointment.beforeNotes && (
                                <div className="flex flex-col gap-2">
                                  <span className="text-sm font-medium">
                                    Notes
                                  </span>
                                  <div className="flex flex-row flex-wrap gap-1 items-start justify-start w-full m-auto">
                                    {appointment.beforeNotes}
                                  </div>
                                </div>
                              )}
                            </div>
                            <div className="bg-gray-100 rounded p-5 flex flex-col gap-4">
                              <span>{"After"}</span>
                              {appointment.afterUrls ? (
                                <div
                                  className={
                                    "flex flex-row flex-wrap gap-1 items-start justify-start w-full m-auto"
                                  }
                                >
                                  {/* "object-cover rounded border-2 border-gray-100 hover:border-blue-600 cursor-pointer max-h-28" */}
                                  {appointment.afterUrls.map(
                                    (attachment, i) => (
                                      <div
                                        key={i}
                                        className="text-sm mt-1"
                                        onClick={(_) => {
                                          setImagePopUp({
                                            display: true,
                                            photoIndex: i,
                                          });
                                          setLightboxImages(
                                            appointment.afterUrls
                                          );
                                        }}
                                      >
                                        <DraggableItem
                                          item={attachment}
                                          dragCB={addAttachmentToSR}
                                        >
                                          <PreviewLink url={attachment} />
                                        </DraggableItem>
                                      </div>
                                    )
                                  )}
                                </div>
                              ) : (
                                <div className="w-48 h-40 m-auto rounded text-sm flex flex-col gap-2 items-center justify-center text-red-600">
                                  {/* <WarningIcon className="w-5 h-5" /> */}
                                  <span className="text-red-400">
                                    Yet to be updated
                                  </span>
                                </div>
                              )}

                              {appointment.afterNotes && (
                                <div className="flex flex-col gap-2">
                                  <span className="text-sm font-medium">
                                    Notes
                                  </span>
                                  <div className="flex flex-row flex-wrap gap-1 items-start justify-start w-full m-auto">
                                    {appointment.afterNotes}
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  );
                })}
              </div>
            )}
          </div>
        </div>
      </DndProvider>
      {imagePopUp.display && (
        <MaterialModal
          className="p-0"
          open={imagePopUp.display}
          setOpen={() => {
            setImagePopUp({ display: false, photoIndex: null });
          }}
        >
          <Carousel
            lightboxImages={{
              images: [...lightboxImages],
              photoIndex: imagePopUp.photoIndex,
            }}
          />
        </MaterialModal>
      )}

      <MaterialModal
        open={galleyModal}
        setOpen={(_) => {
          setGalleryModal(false);
        }}
        label="new-user-modal"
        describedby="create-new-user"
      >
        <SRGallery
          // imgList={(serviceRequest?.mediaUrls || []).concat(
          //   serviceRequest?.ticketMedia
          // )}
          imgList={[
            ...new Set(
              (serviceRequest?.ticketMedia || []).filter(
                (d) =>
                  !(serviceRequest?.blacklistedTicketMedia || []).includes(d)
              )
            ),
          ]}
          onCheckCB={(checked, item) => {
            let status = tempImages.includes(item);
            status
              ? setTempImages(tempImages.filter((i) => i !== item))
              : setTempImages([...tempImages, item]);
          }}
          onSelectCB={(item) => {
            setSelectedImage(item);
          }}
          onSubmitCB={() => {
            setGalleryModal(false);

            var data = Object.assign({}, serviceRequest);
            delete data["appointments"];

            createSR({ ...data, mediaUrls: tempImages })
              .then(() => {
                refreshCB();
                // getServiceRequestData();
                alertSuccess("Successfully Updated Service Request");
              })
              .catch((_) => alertError("Error Updating Service Request"));
            toggleChangeBuilding((_) => false);
            setClientBuildings((_) => undefined);
          }}
          mediaUrls={tempImages}
          srSelectedImages={tempImages}
        />
      </MaterialModal>
      {selectedImage && (
        <MaterialModal
          className="p-0"
          open={selectedImage}
          setOpen={() => {
            setSelectedImage();
          }}
        >
          <Carousel
            lightboxImages={{ images: [selectedImage], photoIndex: 0 }}
          />
        </MaterialModal>
      )}

      {modal && (
        <MaterialModal
          open={modal}
          setOpen={(_) => setModal(false)}
          label="new-user-modal"
          describedby="create-new-user"
        >
          <div className="flex items-center">
            <div className="flex flex-col">
              {(state.status === "STARTED" ||
                state.status === "FINISHED" ||
                state.status === "PICKED_UP") && (
                <>
                  <div className="">
                    <Button variant="contained" component="label">
                      Add Image
                      <input
                        type="file"
                        hidden
                        onChange={handleSelectFile}
                        ref={fileRef}
                        multiple
                      />
                    </Button>
                    {state.attachments?.map((attachment) => (
                      <p>
                        File: {attachment.fileName}
                        <CloseIcon
                          className="cursor-pointer"
                          onClick={(e) => {
                            let fileIndex = state.attachments.map(
                              (item, index) => {
                                if (item.fileName === attachment.fileName) {
                                  return index;
                                }
                              }
                            );
                            let revisedAttachment = state.attachments.splice(
                              fileIndex,
                              1
                            );
                            setAttachments(revisedAttachment);
                          }}
                        />
                      </p>
                    ))}
                  </div>
                  <div className="py-2">
                    <textarea
                      className="bg-gray-100 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white"
                      id="inline-full-name"
                      placeholder="Add Notes"
                      type="text"
                      value={state.notes}
                      onChange={(e) =>
                        setState({ ...state, notes: e.target.value })
                      }
                      rows={3}
                    />
                  </div>
                </>
              )}
              {state.status === "FINISHED" && (
                <div className="">
                  Revisit Required:-{" "}
                  <Switch
                    style={{
                      color: state.revisitRequired ? "primary" : "#000",
                    }}
                    onChange={(e) => {
                      setState((current) => ({
                        ...current,
                        revisitRequired: e.target.checked,
                      }));
                    }}
                  />
                </div>
              )}
              <div className="py-3">
                <div className=" flex flex-row items-center">
                  <div>
                    <Autocomplete
                      id="combo-box-demo"
                      options={getAppointmentStatus(
                        state.status,
                        state.appointmentType
                      )}
                      getOptionLabel={(option) => option.label}
                      style={{ width: 250 }}
                      disableClearable
                      defaultValue={getAppointmentStatus(
                        "",
                        state.appointmentType
                      ).find((item) => {
                        if (item.label === state.status) {
                          return item.label[0];
                        }
                      })}
                      onChange={(event, newValue) => {
                        setState((state) => {
                          return {
                            ...state,
                            status: newValue.value,
                            attachments: [],
                            notes: "",
                          };
                        });
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Appointment Status"
                          variant="outlined"
                        />
                      )}
                    />
                  </div>
                  <div className="">
                    <Button
                      style={{ color: "black", textTransform: "none" }}
                      onClick={(_) => {
                        let data = {
                          ...state,
                          status: state.status.toUpperCase(),
                        };
                        updateStatusCSR(data).then(() => {
                          setModal(false);
                          refreshCB();
                        });
                      }}
                    >
                      Update Status
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </MaterialModal>
      )}
    </MaterialModal>
  );
}
